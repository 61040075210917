import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';

import { strings } from '../../localization/strings';
import StyledLabel from '../common/form/styled-label';
import CommonAnimatedLoader from '../common/animated/loader';
import StyledError from '../common/form/styled-error';
import CommonButtonDefault from '../common/buttons/default';
import Heading2 from '../common/headings/heading2';
import { FormImmunotherapy } from '../../entities/forms/immunotherapy';
import { ModalKeys } from '../../context/modals';
import { useModal } from '../../hooks/use-modal';
import { updateUser } from '../../services/api/users';
import { createAnswer } from '../../services/api/answer';

interface ComponentProps {
  isChild?: boolean;
  token: string;
  customerId?: number;
  patientId?: number;
  productId?: number;
}

function ImmunotherapyFormComponent({ token, isChild, patientId, productId }: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, setError, clearErrors, formState } = useForm<FormImmunotherapy>();
  const navigate = useNavigate();
  const { setVisible } = useModal();

  const treatmentAgreementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'InitiateCheckout');
      window.fbq('track', 'CheckoutStart');
    }

    window.dataLayer.push({
      event: 'InitiateCheckout',
    });

    window.dataLayer.push({
      event: 'CheckoutStart',
    });
  }, []);

  useEffect(() => {
    if (
      Object.keys(formState.errors).length === 1 &&
      formState.errors.treatmentAgreement &&
      treatmentAgreementRef.current
    ) {
      window.scrollTo({ behavior: 'smooth', top: treatmentAgreementRef.current.offsetTop - 10 });
    }
  }, [formState]);

  const onSubmit: SubmitHandler<FormImmunotherapy> = async (data) => {
    try {
      setIsLoading(true);
      const formData: FormImmunotherapy = { ...data };

      await updateUser(token, formData.address, formData.city, formData.zipCode);

      if (productId) {
        const answer = await createAnswer(token, productId);

        if (patientId && answer.hash && formData.treatmentAgreement) {
          navigate(`/payments/checkout/${answer.hash}/${patientId}/${formData.treatmentAgreement && 1}`);
        }
      }
    } catch (e) {
      setIsLoading(false);
      setError('error', { message: (e as Error).message });
    }
  };
  const onOpenModal = (key: string) => {
    setVisible(key, true);
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      <Heading2 className="mb-4">{strings().immunotherapy.titleTreatment}</Heading2>
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <div ref={treatmentAgreementRef} className="mb-16">
          <div className="flex space-x-2">
            <div className="flex items-center h-5">
              <input
                {...register('treatmentAgreement', { required: true })}
                id="treatmentAgreement"
                name="treatmentAgreement"
                type="checkbox"
                className="h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500 checked:bg-blue-900 checked:hover:bg-blue-900"
              />
            </div>
            <label htmlFor="treatmentAgreement" className="text-sm text-gray-900">
              {isChild ? strings().immunotherapy.form.terms.firstRowChild : strings().immunotherapy.form.terms.firstRow}
            </label>
          </div>
          {formState.errors.treatmentAgreement && <StyledError>{strings().error.required}</StyledError>}
          <div className="mt-3">
            <ul className="list-disc ml-10 text-md">
              <li>
                {strings().immunotherapy.form.terms.secondRow}{' '}
                {isChild ? (
                  <CommonButtonDefault
                    className="!text-sm"
                    link
                    onClick={() => onOpenModal(ModalKeys.TreatmentProtocolChild)}
                  >
                    {strings().immunotherapy.form.terms.secondRowLinkText}
                  </CommonButtonDefault>
                ) : (
                  <CommonButtonDefault
                    className="!text-sm"
                    link
                    onClick={() => onOpenModal(ModalKeys.TreatmentProtocol)}
                  >
                    {strings().immunotherapy.form.terms.secondRowLinkText}
                  </CommonButtonDefault>
                )}
              </li>
              {isChild
                ? strings().immunotherapy.form.terms.itemsChild.map((item: any, i: number) => (
                    <li key={`item-${i + 1}`}>{item}</li>
                  ))
                : strings().immunotherapy.form.terms.items.map((item: any, i: number) => (
                    <li key={`item-${i + 1}`}>{item}</li>
                  ))}
            </ul>
          </div>
        </div>
        <Heading2 className="!mb-4">{strings().immunotherapy.titleAddress}</Heading2>
        <div className="mb-5">{strings().immunotherapy.subTitleAddress}</div>

        <div className="grid grid-cols-12 gap-y-6 gap-x-4">
          <div className="col-span-full">
            <StyledLabel htmlFor="address">
              {strings().form.address}
              <span className="text-red-500">*</span>
            </StyledLabel>
            <div className="mt-1">
              <input
                {...register('address', { required: true })}
                type="text"
                id="address"
                name="address"
                autoComplete="given-name"
                className={classNames(
                  'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
                  {
                    'border-red-500': formState.errors.address,
                  },
                )}
              />
              {formState.errors.address && <StyledError>{strings().error.RequiredFillOut}</StyledError>}
            </div>
          </div>
          <div className="col-span-full">
            <StyledLabel htmlFor="city">
              {strings().form.city}
              <span className="text-red-500">*</span>
            </StyledLabel>
            <div className="mt-1">
              <input
                {...register('city', { required: true })}
                type="text"
                id="city"
                name="city"
                autoComplete="given-name"
                className={classNames(
                  'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
                  {
                    'border-red-500': formState.errors.city,
                  },
                )}
              />
              {formState.errors.city && <StyledError>{strings().error.RequiredFillOut}</StyledError>}
            </div>
          </div>
          <div className="col-span-full">
            <StyledLabel htmlFor="zipCode">
              {strings().form.zipCode}
              <span className="text-red-500">*</span>
            </StyledLabel>
            <div className="mt-1">
              <input
                {...register('zipCode', { required: true })}
                type="text"
                id="zipCode"
                name="zipCode"
                autoComplete="given-name"
                className={classNames(
                  'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
                  {
                    'border-red-500': formState.errors.zipCode,
                  },
                )}
              />
              {formState.errors.zipCode && <StyledError>{strings().error.RequiredFillOut}</StyledError>}
            </div>
          </div>
        </div>
        <div className="mt-4 text-gray-500 text-md">{strings().form.requiredFields}</div>

        {formState.errors.error && <StyledError>{formState.errors.error.message}</StyledError>}

        <CommonButtonDefault onClick={() => clearErrors('error')} type="submit" primary className="block mt-10">
          {strings().button.continue}
        </CommonButtonDefault>
      </form>
    </>
  );
}

ImmunotherapyFormComponent.defaultProps = {
  isChild: false,
  customerId: '',
  patientId: '',
  productId: '',
};

export default ImmunotherapyFormComponent;
