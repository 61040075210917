export const strings = {
  upTo: 'Līdz',
  redirecting: 'Režisors...',
  startingFrom: 'no',
  consultation: '+ konsultācijas',
  written: '+ komentārs',
  error: {
    required: 'Ir jāpieņem',
    RequiredFillOut: 'Jāaizpilda',
    quotaReached: {
      title: 'Atvainojiet, šodien nav bezmaksas testu',
      description: 'Aicinām jūs to izmēģināt rīt.',
    },
    feedback: {
      title: 'Mēs paužam nožēlu, ka ir pieļauta kļūda',
      description: 'Laipni atstājiet vērtējumu vēlāk',
    },
    common: {
      title: 'Mēs paužam nožēlu, ka ir pieļauta kļūda',
      description: 'Lūdzu, mēģiniet vēlreiz vēlāk',
    },
    pageNotFound: {
      title: 'Lapa nav atrasta',
      description: 'Lūdzu, pārbaudiet lapas adresi un mēģiniet vēlreiz.',
      goHome: 'Atpakaļ uz augšu',
    },
    fieldIsRequired: 'Šis lauks ir obligāts',
    youMustSelect: 'Pēc izvēles',
    terms: {
      required: 'Pakalpojumu sniegšanas noteikumu pieņemšana ir obligāta.',
    },
    agreeResearch: {
      required: 'Ja nepiekrītat, mēs nevarēsim jūs pārbaudīt un sniegt ieteikumus.',
    },
    agreementForScienceResearch: {
      required: 'Ja nepiekrītat, mēs nevarēsim jūs pārbaudīt un sniegt ieteikumus.',
    },
    selectedPatientId: {
      required: 'Pacienta izvēle ir obligāta',
    },
    firstName: {
      required: 'Nosaukums ir obligāts',
    },
    lastName: {
      required: 'Uzvārds ir obligāts',
    },
    email: {
      required: 'Nepieciešama e-pasta adrese',
      isUsed:
        'Šajā e-pasta adresē jau ir izveidots lietotājs. Lūdzu, izmantojiet pieteikšanās formu vai citu e-pasta adresi.',
    },
    phone: {
      required: 'Tālrunis ir obligāts',
    },
    bank: {
      required: 'Izvēlieties savu maksājuma metodi',
    },
    password: {
      minLength: 'Parolei jābūt vismaz {garuma} rakstzīmēm.',
      same: 'Parolēm jāsakrīt',
    },
    birthDate: {
      required: 'Nepieciešamais dzimšanas datums',
    },
    gender: {
      required: 'Obligāti jānorāda dzimums',
    },
    personCode: {
      required: 'Nepieciešamais personas identifikācijas numurs',
      incorrect: 'Ievadiet savu personas identifikācijas numuru. Ievadītais kods ir nepareizs.',
      length: 'Jābūt vienpadsmit rakstzīmju garai',
    },
    agreeRemoteConsultation: {
      required: 'Ja nepiekrītat, mēs nevarēsim jums sniegt attālinātas konsultācijas.',
    },
    agreeChildResponsibility: {
      required: 'Lai reģistrētu savu bērnu, jums jābūt pilnvarotai personai.',
    },
    agreeTerms: {
      required: 'Ir jāpieņem lietošanas noteikumi',
    },
    YourChildCode: {
      required: 'Nepieciešams personas identifikācijas numurs',
    },
    address: {
      required: 'Ir obligāti jānorāda adrese',
    },
    backendError: 'Ir notikusi kļūda. Lūdzu, mēģiniet vēlreiz vēlāk.',
  },
  loading: {
    wait: 'Pagaidiet...',
    wait_until_check: 'Lūdzu, pagaidiet, līdz mēs no bankas saņemsim maksājuma apstiprinājumu.',
    wait_until_sign_check: 'Sagaidiet dokumenta parakstīšanas apstiprinājumu.',
  },
  button: {
    close: 'Aizvērt',
    continue: 'Turpināt',
    start: 'Sākt no',
    goBack: 'Atgriezties',
    buy: 'Iegādāties',
    order: 'Pasūtiet no',
    understood: 'Saprotu, ka',
    pay: 'Maksāt',
    completedOrder: 'Pasūtiet no',
    check: 'Pārbaudiet',
    login: 'Piesakieties',
    preview: 'Skatīt vietnē',
    createAccount: 'Izveidot kontu',
    createPatient: 'Pacienta izveide',
    sendReminder: 'Nosūtīt atgādinājumu',
    changePassword: 'Mainīt paroli',
    signOrder: 'Reģistrējieties vietnē',
    send: 'Sūtīt uz',
    upload: 'Augšupielādēt',
    complete: 'Apdare',
    pdf: 'Lejupielādēt PDF',
    startQuestionnaire: '',
    download: '',
  },
  products: {
    CONSULTATION: {
      allergens: '0',
      title: 'Attālināta konsultācija ar alergologu',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Pieteikt attālinātu konsultāciju',
      description:
        'Intervijā tiek novērtēti simptomi un to iespējamība, ka tos izraisa alerģija. Tiek veikti testi un sastādīts sākotnējais ārstēšanas plāns.',
      bullets: [
        'Alergologa rakstisks novērtējums un ieteikumi',
        'Iespēja uzdot papildu jautājumus',
        '20 min video zvans ar alergologu',
      ],
    },
    FULL: {
      allergens: '295',
      title: 'Visaptveroša alerģiju testēšanas programma',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Rezervējiet pilnu pārbaudi',
      description:
        'Pētījumā tika pētīta sensibilizācija pret gandrīz 300 alergēniem, kas ietver gandrīz visus alergēnus, kuri var izraisīt tūlītēja tipa alerģiskas reakcijas.',
      bullets: [
        '295 alergēnu asins analīzes (+ līdz 10 apstiprinošajām analīzēm)',
        'Alergologa novērtējums un ieteikumi',
        'Sīkāks alergēnu profila apraksts',
        'Iespēja uzdot papildu jautājumus',
        '20 min video zvans ar alergologu',
      ],
      review: {
        auto: {
          title: 'Automātiska testu novērtēšana',
          price: '+0 €',
          description: 'Mēs novērtēsim, kuri alergēni var izraisīt jūsu norādītos simptomus.',
        },
        written: {
          title: 'Ārsta rakstisks novērtējums',
          price: '+29 €',
          description: 'Alergologs izvērtēs un komentēs jūsu testu rezultātus un sniegs rakstiskus ieteikumus.',
        },
        consultation: {
          title: 'Ārsta novērtējums + konsultācija',
          price: '+49 €',
          description:
            'Jūs apspriedīsiet testu rezultātus un ārstēšanas plānu ar savu alergologu klātienes attālinātā konsultācijā, kā arī saņemsiet rakstisku novērtējumu.',
        },
      },
    },
    SCREENER: {
      allergens: '{allergensCount}',
      title: 'Primārā alerģijas skrīninga programma',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Rezervējiet sākotnējo pārbaudi',
      description:
        'Pētījumā tiek pētīta sensibilizācija {allergensCount} pret alergēniem, kas izraisa 95 % ātra tipa alerģisku reakciju.',
      bullets: [
        '{allergensCount} alergēnu asins analīze',
        'Alergologa rakstisks novērtējums un ieteikumi',
        'Iespēja uzdot papildu jautājumus',
        '20 min video zvans ar alergologu',
      ],
    },
    SCREENER_ASIT: {
      allergens: '{allergensCount}',
      title: 'AllergyChip',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Rezervējiet AllergyChip testu',
      description:
        'Pētījumā tiek pētīta sensibilizācija {allergensCount} pret alergēniem, kas izraisa 95 % ātra tipa alerģisku reakciju.',
      bullets: [
        '{allergensCount} alergēnu asins analīze',
        'Alergologa rakstisks novērtējums un ieteikumi',
        'Iespēja uzdot papildu jautājumus',
      ],
      review: {
        auto: {
          title: 'Automātiska testu novērtēšana',
          price: '+0 €',
          description: 'Mēs novērtēsim, kuri alergēni var izraisīt jūsu norādītos simptomus.',
        },
        written: {
          title: 'Ārsta rakstisks novērtējums',
          price: '+29 €',
          description: 'Alergologs izvērtēs un komentēs jūsu testu rezultātus un sniegs rakstiskus ieteikumus.',
        },
        consultation: {
          title: 'Ārsta novērtējums + konsultācija',
          price: '+49 €',
          description:
            'Jūs apspriedīsiet testu rezultātus un ārstēšanas plānu ar savu alergologu klātienes attālinātā konsultācijā, kā arī saņemsiet rakstisku novērtējumu.',
        },
      },
    },
    FREE_SCREENER_ASIT: {
      allergens: '66',
      title: 'Primārā alerģijas skrīninga programma',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Rezervējiet sākotnējo pārbaudi',
      description:
        'Vai jums ir specifiski jautājumi par alerģijām un to ārstēšanu? Mūsu ārsti palīdzēs uz tiem atbildēt.',
      bullets: ['Alergologa rakstisks novērtējums un ieteikumi', 'Iespēja uzdot papildu jautājumus'],
    },
    FREE_SCREENER: {
      allergens: '28',
      title: 'Primārā alerģijas skrīninga programma',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Rezervējiet sākotnējo pārbaudi',
      description:
        'Vai jums ir specifiski jautājumi par alerģijām un to ārstēšanu? Mūsu ārsti palīdzēs uz tiem atbildēt.',
      bullets: [
        'Alergologa rakstisks novērtējums un ieteikumi',
        'Iespēja uzdot papildu jautājumus',
        '20 min video zvans ar alergologu',
      ],
    },
    IMMUNOTHERAPY_PLAN: {
      allergens: '295',
      title: 'Imūnterapijas plāns + konsultācijas',
      titleResearch: 'Detalizēta alerģiju testēšana, lai izstrādātu imūnterapijas plānu.',
      titleProduct6month: '',
      orderTitle: 'Rezervējiet ārstēšanas plānu',
      description: '..',
      bullets: ['..'],
    },
    TREATMENT_PLAN: {
      allergens: '0',
      title: 'Imūnterapijas ārstēšana 3 mēnešus',
      titleResearch: '',
      titleProduct6month: 'Imūnterapijas ārstēšana 6 mēneši',
      orderTitle: 'Rezervējiet attālināto imūnterapijas procedūru',
      description: '..',
      bullets: ['..'],
    },
    FOOD_ID: {
      allergens: '{allergensCount}',
      title: 'Pārtikas nepanesības testēšana',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Rezervējiet pārtikas nepanesības testu',
      description: 'Pētījumā tiek pētīta jutība pret {allergensCount} pārtikas produktiem.',
      bullets: ['{allergensCount} alergēnu asins analīze'],
      chat: {
        heading: 'Jautājumi par diētu vai pārtikas nepanesamību?',
      },
      legend: {
        description:
          'Ar pārtikas nepanesības testu nosaka IgG antivielu skaitu asinīs. Šis sensibilizācijas veids ir saistīts ar pārtikas nepanesamību. Jo augstāks ir sensibilizācijas līmenis, jo lielāka varbūtība, ka attiecīgais pārtikas produkts var izraisīt simptomus. Pārtikas nepanesība tiek diagnosticēta, ja tests uzrāda pārmērīgu sensibilizāciju un šis pārtikas produkts izraisa simptomus.',
      },
      review: {
        auto: {
          title: '',
          price: '',
          description: '',
        },
        written: {
          title: '',
          price: '',
          description: '',
        },
        consultation: {
          title: '',
          price: '',
          description: '',
        },
      },
    },
  },
  features: {
    ourClinic: 'Allergomedica',
    privateClinic: 'Privāta klīnika',
    visits: {
      title: 'Apmeklējumu skaits',
      hint1: 'Asins ziedošanai tuvākajā klīnikā',
      hint2: 'Dod asinis un uzzini atbildi',
    },
    timeToResult: {
      title: 'Laiks līdz galīgajai atbildei no pirmās reģistrācijas',
      tier1: '7 d.d.',
      tier2: 'no 10 d.d.d.',
    },
    fullResearch: {
      title: 'Molekulārais asins tests',
    },
    screenerResearch: {
      title: 'Visaptverošs alerģijas asins analīzes tests',
    },
    detailedProfile: {
      title: 'Alergologa sagatavots detalizēts jūsu alerģiju profils.',
    },
    recommendations: {
      title: 'Sīki izstrādātas rakstiskas vadlīnijas',
    },
    questions: {
      title: 'Iespēja uzdot jaunus jautājumus vēlāk jums ērtā laikā.',
    },
  },
  product: {
    title: 'Rezervējiet pilnu apsekojumu',
    text: 'Izmantojot mūsu programmu, jūs varēsiet detalizēti novērtēt, vai jūsu simptomus izraisa alergēns un kādi alergēni izraisa šos simptomus. Mēs sniegsim detalizētu alergēnu analīzi, kā arī ieteikumus un ārstēšanas iespējas.',
    recommended: 'Mēs iesakām',
    comingSoon: 'Drīzumā gaidāms',
    select: 'Izvēlieties',
  },
  allergo_expert: {
    title: 'Visaptveroša alerģiju diagnostikas programma',
    bullets: [
      'Alergologa novērtējums un ieteikumi',
      'Sīkāks alergēnu profila apraksts',
      'Attālās konsultācijas, lai atbildētu uz jautājumiem',
      '295 alergēnu asins analīzes (+ līdz 10 apstiprinošajām analīzēm)',
      'Ja jums ir vēl kādi jautājumi, jums būs iespēja uzdot tos mūsu komandai.',
    ],
  },
  short_test: {
    scale_text: ['Neuztraucieties', 'Viegli apgrūtina', 'Vidēji apgrūtinoši', 'Ļoti apgrūtinoši', 'Īpaši apgrūtinoši'],
  },
  shortTestAnswer: {
    title: 'Paldies, ka aizpildījāt anketu.',
    description: {
      research:
        'Pamatojoties uz jūsu atbildēm, alergologs izvēlēsies visprecīzākās testēšanas metodes un, pamatojoties uz rezultātiem, sagatavos jums ieteikumus.',
      consultation: 'Jūsu atbildes ļaus ārstam pirms konsultācijas labāk izprast jūsu gadījumu.',
    },
    weNeedYourAttention: 'Mēs vēlamies pievērst jūsu uzmanību',
    bookConsultation: 'Konsultāciju laika rezervēšana',
    benefitsTitle: 'Ko jūs uzzināsiet?',
    benefits: {
      SCREENER: [
        'Uz kuriem no {allergensCount} populārākajiem alergēniem jums ir alerģija?',
        'Kādi alergēni var izraisīt jūsu uzskaitītos simptomus un kuri ir klīniski nebūtiski.',
        'Kā uzlabot dzīves kvalitāti, pielāgojot dzīvesveidu',
        'Kādas ir jūsu ārstēšanas iespējas',
      ],
      FULL: [
        'Uz kuriem no 295 populārākajiem alergēniem un to molekulām jums ir alerģija?',
        'Kādi alergēni var izraisīt jūsu uzskaitītos simptomus un kuri ir klīniski nebūtiski.',
        'Kā uzlabot dzīves kvalitāti, pielāgojot dzīvesveidu',
        'Kādas ārstēšanas iespējas jums ir pieejamas, vai jums ir piemērota imūnterapija?',
      ],
    },
    viewSample: {
      title: 'Skatiet, kā izskatās testa parauga rezultāts',
      button: 'Skatīt vietnē',
    },
    comparison: {
      title: 'Paskaidrojumi par Allergomedica ar privātajām klīnikām',
      included: 'Izņemts no',
      notIncluded: 'Nav iekļauts',
    },
    defaultProductTitle: 'Visaptveroša alerģiju diagnostikas programma',
  },
  allergyEvaluation: {
    subscribeAnswer: {
      title: 'Ievadiet savu e-pasta adresi',
      description:
        'Lai varētu viegli piekļūt anketai, mēs nosūtīsim jums atbildes saiti uz jūsu norādīto e-pasta adresi.',
      buttonText: 'Sazinieties ar',
    },
  },
  shortAnswer: {
    hello: 'Sveiki,',
    title: 'Mēs esam jums sagatavojuši alerģijas novērtējumu.',
    description:
      'Novērtējums balstās uz 2879 cilvēku statistiku, kuri jau ir izmantojuši Allergomedica pakalpojumus. Pamatojoties uz šiem datiem, mēs esam novērtējuši jūsu norādītos simptomus un veikuši alerģijas novērtējumu.',
    possibility: {
      title: 'Ātrā tipa alerģijas iespējamība',
      items: {
        veryLow: 'Ļoti zems',
        low: 'Zema',
        medium: 'Vidēja',
        high: 'Augsts',
        veryHigh: 'Ļoti augsts',
      },
      hint: 'Novērtējums var palīdzēt jums izlemt, vai ir vērts veikt alerģiju testus, taču to nedrīkst izmantot kā medicīnisku diagnozi.',
    },
    allergens: {
      title: 'Bieži sastopamie alergēni',
      text: 'Tieši šie alergēni visbiežāk izraisa jūsu aprakstītos simptomus.',
      textNoAllergens: 'Jūsu minētos simptomus parasti neizraisa ātra tipa alerģiskas reakcijas.',
    },
    moreBlock: {
      title: 'Kas tālāk?',
      items: {
        low: 'Iespējamība, ka simptomus izraisīs tūlītēja alerģiska reakcija, ir neliela. Iesakām konsultēties ar attiecīgās jomas ārstu, lai novērtētu Jūsu simptomu izcelsmi.',
        high: 'Pastāv liela iespēja, ka simptomus, kas jums rodas, ir izraisījusi ātra tipa alerģiska reakcija. Lai pārbaudītos un būtu mierīgi, iesakām veikt sākotnējo alerģijas testu.',
        veryHigh:
          'Ļoti iespējams, ka simptomus, kas jums rodas, ir izraisījusi ātra tipa alerģiska reakcija. Tomēr to var precīzi diagnosticēt tikai ar alerģijas testu. Mēs iesakām veikt visaptverošu vai sākotnējo alerģijas testu.',
      },
      hint: 'Novērtējums var palīdzēt jums izlemt, vai ir vērts veikt alerģiju testus, taču to nedrīkst izmantot kā medicīnisku diagnozi.',
    },
    symptoms: {
      title: 'Jūsu norādītie simptomi',
      noSymptomsIndicated: 'Simptomi nav norādīti',
      noDetails: 'Bez nosaukuma',
      items: {
        often: 'Bieži',
        common: 'Parasts',
        rare: 'Reti',
      },
      hint: 'Alerģijas var izpausties ar dažādiem simptomiem. Daži simptomi ir specifiski un galvenokārt sastopami alerģiju gadījumā, bet citi ir biežāk sastopami citu slimību gadījumā. Simptomu specifika palīdz noteikt alerģijas risku.',
    },
    periodicity: {
      title: 'Periodiskums',
      hint: 'Atkārtoti simptomi ir bieži sastopama alerģisku reakciju pazīme. Ja novērojat periodiskumu, alerģijas iespējamība ir lielāka.',
    },
    circumstances: {
      title: 'Pamatinformācija',
      hint: 'Ātrā tipa reakcijas rodas uzreiz pēc saskarsmes ar alergēnu, tāpēc, ja pamanāt apstākļus, kas noved pie simptomiem, tas palielina alerģijas iespējamību.',
    },
    duration: {
      title: 'Simptomu ilgums',
      items: {
        someMonth: 'Vairāki mēneši',
        justNow: 'Tikai to, ko',
        fiveYear: '5+ gadi',
      },
      hint: 'Ja simptomi saglabājas un atkārtojas ilgāku laiku, pastāv lielāka alerģijas izraisītu simptomu iespējamība.',
    },
  },
  test_answer: {
    navigation: {
      allergyProfile: 'Alerģijas profils',
      recommendations: 'Ieteikumi',
      allergens: 'Visi alergēni',
    },
    hello: 'Sveiki,',
    hero_subtitle: 'Jums ir veikta visaptveroša alerģiju novērtēšanas programma.',
    hero_text: 'Aptaujā jūs norādījāt {skaitli} Jums ir {nopūles} un iespējamas alerģijas {saistīti simptomi}',
    yourAnswerIsNotReady: 'Esam gandarīti, ka jūs interesē, bet jūsu atbilde vēl nav gatava.',
    youAreSensitive: 'Jums ir diagnosticēta sensibilizācija',
    noSensitivity: 'Jums nav diagnosticēta sensibilizācija.',
    allergenGroupPlural: 'alergēnu grupa|alergēnu grupas|alergēnu grupas',
    origin_of_symptoms: 'Simptomu izcelsme',
    here_we_review_diseases_and_symptoms:
      'Šeit sniegts pārskats par slimībām un simptomiem, ko var izraisīt alerģiskas reakcijas.',
    typical_symptoms: 'Tipiski simptomi',
    allergens: 'alergēnus',
    possibly_provoking_symptoms: 'Potenciāli provocējoši simptomi',
    interpretation_of_sensitivity_intensity: 'Rezultātu interpretācija',
    no_symptoms: 'Nav ar to saistītu simptomu',
    unclear_origin: 'Nenoteiktas izcelsmes',
    doctorPosition: {
      LINAS: 'Vecākais alergologs un klīniskais imunologs',
      ANA: 'Alergologs',
      EGLE: 'Alergologs',
      AGNE: 'Medicīnas ārsts, vecākais rezidents alerģijā un klīniskajā imunoloģijā',
      KESTUTIS: 'Alergologs',
      LAIMA: 'Alergologs un klīniskais imunologs',
      GABIJA: 'Ārsts',
    },
    detailedAllergenProfile: {
      title: 'Detalizēti izstrādāts alergēnu profils',
      description:
        'Šeit mēs detalizēti aplūkosim iespējamās alerģijas, ko esam atklājuši asins analīzē, un izskaidrosim to saistību un nozīmi.',
    },
    unidentifiedSymptoms: {
      title: 'Neidentificētas izcelsmes simptomi',
    },
    primaryAllergen: {
      title: 'Galvenais alergēns',
      tooltip:
        'Alergēns, kas tiek uzskatīts par savas grupas (piemēram, koku ziedputekšņi) pārstāvi un ir visbiežākais alerģisku reakciju cēlonis. Šis alergēns parasti ir pirmais alergēns, kas izraisa alerģiju.',
    },
    noPrimaryAllergen: 'Nav būtisku alergēnu',
    components: {
      title: 'Komponentes',
      onlyInFullResult: 'Atsevišķus alergēnu komponentus nosaka tikai ar detalizētu molekulāro alerģijas testēšanu.',
      tooltip:
        'Patiesībā cilvēks kļūst jutīgs nevis pret visu alergēnu, bet gan pret tā sastāvdaļām - komponentēm. Vienam alergēnam parasti ir vairākas dažādas komponentes, bet ne visām tā komponentēm piemīt alerģiskas īpašības. Cilvēks var būt sensibilizēts pret vienu vai vairākām dažādām šī alergēna komponentēm. Visbiežāk sastopamās komponentes ir olbaltumvielas. Komponentes apzīmē ar simboliem, kas sastāv no alergēna nosaukuma saīsinājuma latīņu valodā un skaitļa. Piemēram, bērza (Betula verrucosa) putekšņu pirmo komponenti apzīmē ar Bet v 1. Komponentes palīdz noteikt galvenos alergēnus, prognozēt noteiktu alerģisku reakciju risku un izvēlēties specifisku ārstēšanu.',
    },
    secondaryAllergens: {
      title: 'Citi saistītie alergēni',
      onlyInFullResult:
        'Saistītos alergēnus un krusteniskās reakcijas mēs varam noteikt tikai ar visaptverošu molekulāro alerģijas testu.',
      tooltip:
        'Alergēni, kuriem ir strukturālas līdzības ar grupas galveno alergēnu, un tāpēc tie arī var izraisīt alerģiskus simptomus. Vairumā gadījumu sensibilizācija pret šiem alergēniem rodas vēlāk nekā pret galveno alergēnu.',
    },
    relatedSymptoms: {
      title: 'Iespējamie simptomi, ko izraisa šādi alergēni',
      tooltip:
        'Šeit ir uzskaitīti visi iespējamie simptomi, ko var izraisīt šie alergēni. Izceltie simptomi, kurus anketā esat atzīmējis kā traucējošus.',
    },
    activePeriod: {
      title: 'Attiecīgais periods',
      tooltip: 'Iepriekš minētie alergēni visbiežāk sastopami turpmāk minētajā periodā.',
      months: 'Ar treknrakstu izceltie mēneši ir būtiski',
    },
    viewAllAllergens: 'Apskatīt visus alergēnus, kas testēti attiecībā uz {num}',
    viewRecommendations: 'Apskatīt ieteikumus',
    symptoms: 'Simptomi',
    tiringPlural: 'apgrūtinoša|apgrūtinoša|apgrūtinoša',
    relatedSymptomsPlural: 'saistītais simptoms|saistītie simptomi|saistītie simptomi',
    provokePlural: 'izprovocēts|izprovocēts|izprovocēts',
    symptomsPlural: 'simptoms|simptoms|simptomi',
    no_allergens: 'Provocējoši alergēni nav atrasti',
    sensitivity: ['Nav empātijas', 'Viegla empātija', 'Vidēja empātija', 'Augsta empātija', 'Augsta empātija'],
    sensitivityShort: ['Nē', 'Easy', 'Vidēja', 'Augsts', 'Augsts'],
    sensitivitySuffix: 'kas var izraisīt ',
    manySymptoms: 'daudzi simptomi',
    noSymptoms: 'kas nedrīkst izraisīt simptomus',
    noSensitivityAllergensListTitle:
      'Jums ir veikti testi, lai novērtētu iespējamo sensibilizāciju pret {num} alergēniem.',
  },
  recommendations: {
    title: 'Ieteikumi',
    titleActions: 'Sāciet ar šādiem ieteikumiem',
    titleAll: 'Visi ieteikumi',
    otherRecommendations: 'Citi ieteikumi',
    description:
      'Lai sāktu, esam atlasījuši dažus dzīvesveida un ārstēšanas ieteikumus, kas var uzlabot jūsu dzīves kvalitāti. Izmēģiniet tos un pārbaudiet, vai jūtat uzlabojumus. Pamatojoties uz jūsu atsauksmēm, mēs atjaunināsim ieteikumus, lai tos pielāgotu jums. Lūdzu, ņemiet vērā, ka dažiem no ieteikumiem ir nepieciešama alergologa konsultācija.',
    testedGood: 'Iekļaut šo ieteikumu savā dzīvesveidā',
    testedBad: 'Mēs meklēsim citus veidus, kā palīdzēt',
    testedTime: 'Testēts {date}',
    button: {
      work: 'Pieejams vietnē',
      notWork: 'Nedarbojas',
      testedWork: 'Pārbaudīts ar',
      testedNotWork: 'Pārbaudīts ar',
    },
  },
  textPage: {
    remoteConsultation: {
      title: 'Noteikumi par konsultācijām no attāluma',
    },
    privacyPolicy: {
      title: 'Konfidencialitātes politika',
    },
    termsAndConditions: {
      title: 'Pakalpojumu sniegšanas noteikumi',
    },
    signAgreement: {
      title: 'Piekrišanas parakstīšana',
    },
  },
  bloodRegistration: {
    title: 'Izvēlieties klīniku un laiku, kad ņemsiet asinis.',
    selectClinicStep: 'Izvēlieties klīniku',
    pickerPlaceholder: 'Izvēlieties...',
    completedTitle: 'Lieliski!',
    completedLocation: 'Jūs esat izvēlējies {virsraksts} ({adrese}).',
    completedFurtherAction: 'Zvaniet uz klīniku pa tālruni:',
    completeFurtherActionSuffix: 'un vienojieties par apmeklējuma laiku.',
    pickUpBoxPlaceholder: 'Meklēt pasta nodaļu...',
  },
  dashboard: {
    orderNewItem: 'Rezervēt jaunu programmu',
    title: 'Kas tālāk?',
    noOrders: 'Jums, šķiet, nav pasūtījumu',
    descriptionNoBloodRegistration: 'Pēc tam jums būs jāreģistrējas izvēlētajā klīnikā un jānodod asinis.',
    descriptionNoBlood: 'Viss, kas jums jādara, ir jādod asinis, un tad mēs varam veikt testu un novērtēt rezultātus.',
    tasks: {
      orderCompletedHeading: 'Jūsu rezultāts ir gatavs',
      orderCompletedDescription:
        'Mūsu speciālisti veica asins analīzes un izvērtēja rezultātus. Tagad varat apskatīt testa atbildi',
      orderCompletedDescriptionConsultation: 'Mūsu eksperti jums ir sagatavojuši ieteikumus.',
      incompleteHeading: 'Veiciet šādus uzdevumus',
      completedHeading: 'Jūs esat izpildījis visus uzdevumus!',
      description:
        'Kad mūsu eksperti būs veikuši testu, mēs jūs par to informēsim pa e-pastu {email}. Atbildi uz aptaujas jautājumiem varēsiet atrast sadaļā "Atbilde" iepriekš.',
      giftCouponReady: '',
    },
    bloodRegistration: {
      incompleteTitle: 'Reģistrējieties asins analīzes veikšanai',
      incompleteDescription:
        'Asins analīzē tiks veikts visaptverošs tests 295 alergēnu noteikšanai un līdz 10 apstiprinošiem testiem. ',
      completed: 'Veiksmīga reģistrācija asins savākšanai',
      callClinicByPhone: 'Zvaniet uz klīniku pa tālruni {phone}, lai norunātu sev ērtu tikšanās laiku.',
      completedDescription:
        'Asins savākšanas pakalpojums jums neradīs papildu izmaksas. Lūdzu, klīnikā norādiet, ka esat ieradies uz asins ņemšanu Allergomedica alerģijas programmas ietvaros, un uzrādiet e-pastu, kas apstiprina jūsu reģistrāciju.',
      selectedClinic: 'Jūsu izvēlētā klīnika:',
      selectedTime: 'Jūsu reģistrācijas laiks:',
      button: 'Reģistrējieties vietnē',
    },
    research: {
      completed: 'Veiksmīgi aizpildīta anketa',
      description: 'Ja vēl neesat, iesniedziet savas asinis, un mēs jums paziņosim, kad atbilde būs gatava.',
    },
    orderedAt: 'Rezervēts:',
    orderId: 'Pasūtījuma numurs:',
    status: {
      ongoing: 'Notiek',
      completed: 'Īstenots',
    },
    order: 'Pasūtiet no',
    resultPreview: 'Apskatīt atbildi',
  },
  form: {
    password: 'Parole',
    email: 'E-pasts',
    surname: 'Uzvārds',
    name: 'Nosaukums',
    phone: 'Tālrunis',
    phoneNo: 'Tālruņa numurs',
    birthDate: 'Dzimšanas datums',
    placeholderBirthDate: 'Ievadiet savu dzimšanas datumu',
    birthDateFormat: 'datuma atdalīšanai izmantojiet defisi, piemēram, 1999-12-08.',
    gender: 'Dzimums',
    genderSelect: 'Izvēlieties dzimumu',
    genderMale: 'Vīriešu',
    genderFemale: 'Sieviete',
    genderPickerPlaceholder: 'Izvēlieties dzimumu',
    personCode: 'Personas kods',
    newPassword: 'Jauna parole',
    repeatPassword: 'Paroles atkārtošana',
    YourPersonalIdNo: 'Jūsu personas kods',
    YourChildCode: 'Jūsu bērna personas identifikācijas numurs',
    spouseName: 'Laulātā vārds',
    spouseSurname: 'Laulātā uzvārds',
    spouseEmail: 'Laulātā e-pasta adrese',
    address: 'Adrese',
    city: 'Pilsēta',
    zipCode: 'Pasta indekss',
    atLeastEightSymbols: 'Vismaz 8 rakstzīmes',
    agreementForScienceResearch:
      'Es piekrītu, ka mana personalizētā informācija un asins paraugs tiek izmantots zinātniskiem mērķiem.',
    alertSuccessAnswer: {
      title: 'Reģistrācija veiksmīga',
      text: 'Paldies! Jūsu ērtībai saite uz atbildi ir atrodama norādītajā e-pasta adresē.',
    },
    alertDangerAnswer: {
      title: 'Neizdevās reģistrēties',
      text: 'Jūs esat ievadījis nepareizu e-pasta adresi.',
    },
    requiredFields: 'Ar * atzīmētie lauki ir obligāti.',
  },
  diForm: {
    title: 'Lūdzu, ievadiet dalībnieka kodu',
  },
  checkout: {
    success_title: 'Veiksmīgs pasūtījums',
    success_text: 'Jūs esat saņēmis maksājuma apstiprinājuma e-pasta vēstuli uz ievadīto e-pasta adresi.',
    sign_success_title: 'Dokuments ir veiksmīgi parakstīts',
    sign_success_text: 'Uz zemāk norādīto e-pasta adresi esat saņēmis vēstuli, kas apstiprina jūsu abonēšanu.',
    titleContactInfo: 'Kontaktinformācija',
    titleSpouseContactInfo: 'Laulātā kontaktinformācija',
    titleChooseBank: 'Izvēlieties savu maksājuma metodi',
    titleInfoForRemoteConsultation: 'Informācija attālinātai konsultācijai',
    explanationTextInfoForRemoteConsultation:
      'Personas kods ir nepieciešams, lai saņemtu recepšu zāles un ievadītu savu konsultāciju e-veselībā.',
    explanationTextInfoForChildsResearch:
      'Bērniem, kas jaunāki par 12 gadiem, ir nepieciešama abu vecāku piekrišana. Uz norādīto e-pasta adresi nosūtīsim saiti, lai parakstītu dokumentu elektroniski.',
    failed: {
      title: 'Pasūtījums neizdevās',
      description: 'Mēs nevarējām saņemt jūsu maksājumu. Lūdzu, mēģiniet vēlreiz vai sazinieties ar mums',
    },
    loginTitle: 'Piesakieties savā kontā',
    loginDescription:
      'Ja esat jau iepriekš pie mums reģistrējies, piesakieties savā kontā, un jūs varēsiet sekot līdzi savas programmas statusam un veikt turpmākas darbības.',
    titleYourOrder: 'Jūsu pasūtījums',
    titleBilling: 'Izrakstīšanās no',
    hide: 'Paslēpt',
    iHaveDiscount: 'Man ir atlaides kods',
    discount: 'Atlaide',
    subTotal: 'Starpsumma',
    discountCode: 'Atlaides kods',
    amount: 'Maksājamā summa',
    checkboxIHaveReadAnd: 'Esmu izlasījis un piekrītu',
    checkboxPrivacyPolicy: 'Konfidencialitātes politika',
    checkboxAnd: 'un',
    checkBoxTermsOfService: 'Pakalpojumu sniegšanas noteikumi',
    checkboxIAgreeThatImunodiagnostika:
      'Es piekrītu, ka UAB Immunodiagnostics apstrādā manus veselības datus konta izveides, testu veikšanas, veselības novērtēšanas un ieteikumu sniegšanas nolūkā.',
    checkboxIAgreeToGetDoctorAdvice:
      'Es piekrītu saņemt no sava ārsta padomus par dzīvi ar alerģijām, veselības jaunumiem un īpašiem piedāvājumiem. Jūs vienmēr varat viegli atteikties no abonēšanas',
    checkboxPayseraText:
      'Lūdzu, ņemiet vērā, ka Paysera sniegs Jums konta informāciju un maksājumu iniciēšanas pakalpojumus saskaņā ar šādiem pakalpojumu noteikumiem.',
    checkboxPayseraTerms: 'noteikumi',
    checkboxPayseraAgreement:
      '. Turpinot maksāt, jūs apstiprināt, ka piekrītat šo pakalpojumu sniegšanai un to noteikumiem un nosacījumiem.',
    checkboxAgreeRemoteConsultation: 'Esmu izlasījis un piekrītu ',
    checkboxAgreeRemoteConsultationSuffix: 'noteikumus par telekonsultācijām.',
    checkboxAgreeChildResponsibility:
      'Es apliecinu, ka esmu persona, kas ir pilnvarota pieņemt lēmumus par bērna veselību.',
    previewProfile: 'Profilam',
    titleResearch: 'Piekrišanas parakstīšana',
    SigningOfDocumentsDokobit: 'Dokumentu parakstīšana notiks, izmantojot Dokobit sistēmu.',
    showOrder: 'Rādīt pasūtījumu',
    fillQuestionnaire: '',
  },
  registration: {
    title: 'Izveidot kontu',
    text: 'Kad esat izveidojis kontu, varat turpināt darbu ar savu lietotni, un testa rezultātus saņemsiet vēlāk.',
    haveAccount: 'Jums jau ir konts?',
    login: 'Piesakieties, lai',
  },
  login: {
    title: 'Piesakieties, lai',
    forgotYourPasswordLink: 'Aizmirsāt paroli?',
    dontHaveAccount: 'Jums nav konta?',
    register: 'Reģistrējieties vietnē',
  },
  forgotPassword: {
    requestPasswordReset: 'Paroles atgādinājums',
    successTitle: 'Paroles atgādinājuma saite, kas nosūtīta uz ievadīto e-pasta adresi.',
    finishPasswordReset: 'Mainīt paroli',
    changePasswordSuccessTitle: 'Paroles atjaunināšana',
    linkToLogin: 'Atgriezties pie pieteikšanās',
  },
  selectPatient: {
    title: 'Izvēlieties, kas tiks pārbaudīts:',
    label: 'Izvēlieties kādu no turpmāk sniegtajiem sarakstiem vai pievienojiet jaunu.',
    labelRadio: 'Izvēlieties pacientu vai izveidojiet jaunu pacientu',
    addNewPatient: 'Pievienot jaunu pacientu',
    button: 'Turpināt',
  },
  createPatient: {
    title: 'Papildu informācija pacientiem',
    label: 'Tiek veikta testēšana:',
    addNewPatient: 'Pievienot jaunu pacientu',
    button: 'Turpināt',
    agreementForSienceResearch: 'Turpināt',
  },
  createPatientChild: {
    title: 'Papildu informācija par jūsu bērnu',
    label: 'Tiek veikta testēšana:',
    addNewPatient: 'Pievienot jaunu pacientu',
    button: 'Turpināt',
  },
  navigation: {
    orders: 'Pasūtījumi',
    result: 'Atbilde uz',
    consultations: 'Jautājiet',
    yourProfile: 'Mans profils',
    signOut: 'Iziet no sistēmas',
    selectItem: 'Izvēlieties lapu',
    comingSoon: 'Drīzumā gaidāms',
    openMainMenu: 'Atveriet galveno izvēlni',
  },
  profileAllergens: {
    weHaveEvaluated: 'Kopumā mēs novērtējām',
    allergensTotal: 'alergēnus',
    footnotes: {
      prefix: 'Asins analīžu datu avotu var atrast šādā adresē.',
      link: 'šeit',
      suffix: '(atbildi var uzzināt, ievadot pacienta dzimšanas datumu).',
    },
    table: {
      allergen: 'Alergēni',
      component: 'Komponente',
      biochemicalDesignation: 'Proteīnu grupa',
      value: 'Nozīme',
      strength: 'Spēks',
    },
    title: {
      researchOverview: 'Pētījuma pārskats',
      researchResults: 'Pētījuma rezultāti',
    },
    tableOverview: {
      group: 'Grupa',
      amount: 'Analītu daudzums',
    },
  },
  legend: {
    title: 'Rezultātu interpretācija',
    description:
      'Sensibilizācijas stiprums tieši nenorāda, vai reaģējošais alergēns patiešām izraisīs simptomus. Tomēr, jo izteiktāka ir sensibilizācija, jo lielāka ir varbūtība, ka rezultāts ir klīniski nozīmīgs.',
    senseHigh: 'Augsta sensibilizācija (> 3,5 kU/l)',
    senseMedium: 'Vidēja sensibilizācija (0,7 - 3,49 kU/l)',
    senseLow: 'Viegla sensibilizācija (0,35 - 0,69 kU/l)',
    senseNone: 'Nav sensibilizācijas (0 - 0,34 kU/l)',
    noNum: {
      senseHigh: 'Augsta empātija',
      senseMedium: 'Vidēja empātija',
      senseLow: 'Viegla empātija',
      senseNone: 'Nav empātijas',
    },
  },
  order: {
    steps: ['Aizpildiet anketu', 'Maksāt pie', 'Ziedot asinis', 'Saņemiet atbildi'],
    stepsConsultation: ['Aizpildiet anketu', 'Maksāt pie', 'Sazinieties ar savu ārstu', 'Saņemiet ieteikumus'],
    stepsResearch: ['Aizpildiet anketu', 'Parakstiet piekrišanu', 'Ziedot asinis', 'Saņemiet atbildi'],
    stepsImmunotherapy: ['Aizpildiet anketu', 'Maksāt pie', 'Ziedot asinis', 'Mēs izveidosim ārstēšanas plānu'],
    stepsQuestionnaireAfterSale: ['', '', '', ''],
  },
  chat: {
    inputPlaceholder: 'Jūsu ziņa...',
    heading: 'Jautājumi par alerģijām?',
    headingHasDr: 'Jautājumi?',
    description: 'Uzdodiet tos mūsu ekspertiem',
    descriptionHasDrPlural: 'Jūsu ārsts|Jūsu ārsti',
    replyTimeDescription: 'Mēs parasti atbildam dažu stundu laikā',
    doctorsTitle: 'Mūsu ārstu komanda',
    consultation: {
      title: 'Attālās konsultācijas',
      description:
        'Vēlaties apspriest savus jautājumus attālinātās konsultācijas laikā? Pirmā konsultācija ir bezmaksas.',
      button: 'Reģistrējieties vietnē',
    },
  },
  questionnaireConsultationIntro:
    'Mēs garantējam, ka konsultāciju saņemsiet 2 darba dienu laikā pēc maksājuma veikšanas.',
  questionnaireGenericIntro:
    'Aizpildiet anketu, un mēs jums ieteiksim, kura Allergomedica alerģijas testēšanas programma jums ir vispiemērotākā.',
  questionnaireCheckAllergyIntro:
    'Vai jums ir aizdomas par alerģiju? Šī anketa palīdzēs jums novērtēt, cik liela ir varbūtība, ka jūsu simptomus izraisa ātra tipa alerģiska reakcija.',
  questionnaireDuration: 'Aptauja aizņems līdz 5 minūtēm.',
  doctors: [
    {
      name: 'Linas Griguola',
      nameAccusative: 'Lino Griguola',
      role: 'Vecākais alergologs un klīniskais imunologs',
      subrole: '"Allergomedica vecākais ārsts',
      imageUrl: 'LINAS',
      infoLink: 'linas-griguola',
    },
    {
      name: 'Ana Kozlovska',
      nameAccusative: 'Anna Kozlovska',
      role: 'Alergologs un klīniskais imunologs; bērnu alergologs',
      subrole: '"Allergomedica ārsts',
      imageUrl: 'ANA',
      infoLink: 'ana-kozlova',
    },
    {
      name: 'Eglė Žilėnaitė',
      role: 'Alergologs un klīniskais imunologs',
      subrole: '"Allergomedica ārsts',
      imageUrl: 'EGLE',
      infoLink: 'egle-zilenaīts',
    },
    {
      name: 'Kęstutis Černiauskas',
      role: 'Alergologs un klīniskais imunologs',
      subrole: '"Allergomedica ārsts',
      imageUrl: 'KESTUTIS',
      infoLink: 'kestutis-cerniauskas',
    },
    {
      name: 'Laima Aleksandravičiūtė',
      role: 'Alergologs un klīniskais imunologs',
      subrole: '"Allergomedica ārsts',
      imageUrl: 'LAIMA',
      infoLink: 'laima-aleksandraviciute',
    },
  ],
  reviewsBanner: {
    title: 'Kā jums patīk {uzņēmuma nosaukums}?',
    text: 'Būsim ļoti pateicīgi, ja dalīsieties savās pārdomās. Tas aizņems 1-2 minūtes.',
    button: 'Lasīt vairāk',
    dismiss: 'Aizvērt',
  },
  consultationBanner: {
    title: 'Vēlaties atrast veidu, kā mazināt vai izārstēt simptomus? Vai vienkārši vēlaties padomu?',
    text: 'Pievienojiet attālinātu konsultāciju ar ārstu, lai veiktu pilnīgu izmeklēšanu. Konsultācijas laikā varat apspriest\ntesta rezultātus un izveidot ārstēšanas plānu. To var izdarīt rezervācijas procesa laikā.',
  },
  feedback: {
    success_title: 'Paldies, ka palīdzat mums pilnveidoties!',
    success_text: 'Jūsu atbilde ir veiksmīgi nosūtīta.',
  },
  consultationSuccess: {
    success_title: 'Paldies!',
    success_text: 'Jūsu reģistrācija ir veiksmīga',
  },
  calendar: {
    title: 'Ārsta brīvais laiks',
    freeTime: 'laiki',
    titleFreeTime: 'Bezmaksas laiki - ',
    previousMonth: 'Pagājušajā mēnesī',
    nextMonth: 'Nākamais mēnesis',
    noAppointments: 'Nav brīvā laika',
    schedule: 'Grafiks attiecas uz',
    showDates: 'Izstāžu datumi',
    weekDays: {
      M: 'P',
      Tue: 'A',
      W: 'T',
      T: 'K',
      F: 'P',
      S: 'Š',
      Su: 'S',
    },
  },
  immunotherapy: {
    titleTreatment: 'Piekrišana ārstēšanas uzsākšanai',
    titleAddress: 'Zāļu piegādes adrese',
    subTitleAddress: 'Kādā adresē vēlaties saņemt zāles?',
    form: {
      terms: {
        firstRow: 'Es piekrītu saņemt ASIT ārstēšanu.',
        firstRowChild: 'Es piekrītu, ka mans bērns saņem ASIT ārstēšanu.',
        secondRow: 'Esmu iepazinies ar ',
        secondRowLinkText: 'ārstēšanas protokols.',
        items: [
          'Es saprotu paredzētās ārstēšanas mērķi, tās ilgumu, efektivitāti un nevēlamu blakusparādību iespējamību;',
          'Es viņus informēju par savām slimībām un medikamentiem, ko lietoju;',
          'Es uzdevu jautājumus par šo ārstēšanas metodi un saņēmu atbildes;',
        ],
        itemsChild: [
          'Es saprotu paredzētās ārstēšanas mērķi, tās ilgumu, efektivitāti un nevēlamu blakusparādību iespējamību;',
          'Es viņus informēju par sava bērna slimībām un medikamentiem, ko viņš lieto;',
          'Es uzdevu jautājumus par šo ārstēšanas metodi un saņēmu atbildes;',
        ],
      },
    },
    successText: 'Apsekojuma datu saglabāšana.',
    treatmentPlan: {
      title: 'Ārstēšanas plāns',
      titleDuration: 'Atlikušais laiks ārstēšanas plāna pārskatīšanai',
      subtitleAllergenPlural: 'Alergēni|Alergēni',
      subtitleSymptoms: 'Simptomi',
      subtitleDrugsForSymptoms: 'Zāles simptomu mazināšanai',
    },
    treatmentProgress: {
      title: 'Ārstēšanas progress',
      titleSymptoms: 'Simptomu smaguma pakāpe',
      titleProgressBlock: 'Kopējais progress',
      titleChart: 'Simptomu smaguma izmaiņas laika gaitā',
      chartText: 'Ārstēšanas progress %',
    },
    treatmentDayPlural: 'diena|dienas|days',
    tabs: {
      ask: 'Jautājiet',
      progress: 'Progress',
      plan: 'Plāns',
    },
  },
  anamnesis: {
    questionnaire: {
      description:
        'Pirms vizītes aizpildiet anketu par simptomiem, kas jums rodas. Tas ļaus mums labāk izprast jūsu situāciju un labāk izmantot konsultācijai atvēlēto laiku.',
      submit: {
        title: 'Aptaujas datu nosūtīšana',
        privacypolicy1: 'Noklikšķinot uz sūtīt, jūs piekrītat, ka jūsu dati tiek kopīgoti ar',
        privacypolicy1IsSecond: 'Noklikšķinot uz pabeigt, jūs piekrītat, ka jūsu dati tiek kopīgoti ar',
        privacypolicy2: 'un piekrist',
        privacypolicyLinkTex: 'sistēmas konfidencialitātes politika',
        termsAndConditions1: 'un ',
        termsAndConditionsLinkText: 'lietošanas noteikumi.',
        isSecond: {
          title: 'Paldies, šajā gadījumā anketa nav jāaizpilda.',
          title2: 'Jūsu ārsts jau ir iepazinies ar jūsu situāciju.',
        },
      },
      successTitle: 'Jūsu anketa ir veiksmīgi nosūtīta ārstam.',
      error: 'Nevar nosūtīt',
      upload: {
        title: 'Papildu informācijas augšupielāde',
        description: 'Augšupielādējiet papildu informāciju, lai palīdzētu ārstam ātrāk novērtēt jūsu situāciju:',
        list: [' Jūsu ādas simptomu fotoattēli.', 'Alerģijas testa rezultāti'],
        success: 'Jūsu dati ir veiksmīgi nosūtīti ārstam',
        noPatientTitle: 'Nepareiza saite.',
        noPatientText: 'Sazinieties ar konsultējošo ārstu.',
      },
    },
  },
  selectConsultationReview: {
    title: 'Izvēlieties, kā vēlaties novērtēt rezultātus:',
  },
  treatmentProtocol: {
    title: 'Ārstēšanas protokols',
  },
  locations: {
    workingHours: 'Darba laiks:',
  },
  meta: {
    description:
      'Alerģisku slimību progresīva diagnostika un ārstēšana. Alerģijas testēšana un alergēnu testēšana jūsu mājās. Allergomedica - dzīvei bez alerģijas.',
    title: 'AllergoMedica - mūsdienīgs veids, kā izprast savas alerģijas.',
    url: 'https://app.allergomedica.lv',
  },
  footer: {
    companyInfo: 'Imunodiagnostika, UAB uzņēmuma kods: 303064228',
    address: 'Molėtų g. 16, Didžiosios Riešės k., LT-14260 Vilniaus r.',
    phone: '+370 640 65680',
    email: 'info@allergomedica.lv',
  },
  FOOD_ID: {
    test_answer: {
      navigation: {
        allergyProfile: 'Pārskats',
        recommendations: 'Ieteikumi',
        allergens: 'Aptaujas atbilde',
      },
      hello: 'Sveiki,',
      hero_subtitle: 'Pārskats par jūsu situāciju',
      hero_text: 'Aptaujā jūs norādījāt {skaitli} Jums ir {nopūles} un iespējamas alerģijas {saistīti simptomi}',
      summary: {
        weight: 'Svars',
        kg: 'kg',
        height: 'Augstums',
        bmi: 'Masas indekss',
        BMI: 'KMI',
        cm: 'cm',
      },
      youAreSensitive: 'Jums ir diagnosticēta sensibilizācija',
      noSensitivity: 'Jums nav diagnosticēta paaugstināta jutība pret testētajiem pārtikas produktiem.',
      allergenGroupPlural: 'pārtika|pārtikas produkti|pārtikas produkti.',
      yourSymptoms: 'Jūsu simptomi',
      profileAllergens: {
        weHaveEvaluated: 'Kopējā pārbaudītā empātija',
        allergensTotal: 'pārtikas produktiem',
        table: {
          allergen: 'Pārtikas produkts',
          strength: 'Sensibilizācijas klase / stiprums',
        },
      },
      habits: {
        youAreSensitivePlural: 'Tu esi gatavs|Tu esi gatavs|Tu esi gatavs',
        noHabits: 'Jums nav identificēti uztura vai dzīvesveida paradumi, kas varētu izraisīt simptomus.',
        habitsPlural: 'uzturs un dzīvesveida paradumi|uztura un dzīvesveida paradumi|uztura un dzīvesveida paradumi.',
        canProvokeSymptoms: 'kas var izraisīt simptomus.',
        canProvokeYou: 'Var provocēt jūs',
        symptoms: 'Simptomi',
        youAreSensitiveStaticTitle: 'Jūsu noteiktie uztura un dzīvesveida paradumi, kas var izraisīt jūsu simptomus.',
        canProvokeYouPlural: 'Jums var izraisīt šādus simptomus',
      },
      symptoms: {
        title: 'Pārskatīsim jūsu minētos simptomus',
        titleHabitsThatCanProvokeSymptom: 'Paradumi, kas var izraisīt šo simptomu:',
        titleFoodThatCanProvokeSymptom: 'Pārtikas produkti, kas var izraisīt šo simptomu:',
        titleOtherFactors: 'Citi faktori:',
        factoriesWhich: 'Faktori, kas',
      },
      emptyState: '',
      emptyStateRecommendationsTitle: '',
      emptyStateRecommendations: '',
    },
    recommendations: {
      title: 'Ieteikumi',
      description:
        'Izmaiņas uzturā un dzīvesveida paradumos, kas varētu palīdzēt uzlabot jūsu pašsajūtu un kas ir īpaši atlasītas tieši jums.',
      titleProducts: 'Produkti, kurus iesakām samazināt vai aizstāt',
      titleProductSymptoms: 'Izraisīti simptomi:',
      titleHowToAvoid: 'Kā no tā izvairīties?',
      titleHowToFollowRecommendations: 'Kā ievērot ieteikumus?',
      textHowToFollowRecommendations:
        'Ja šie ieteikumi neatrisina traucējošos simptomus 3-4 nedēļu laikā vai ja šajā laikā parādās trauksmes simptomi, nekavējoties sazinieties ar savu ģimenes ārstu vai gastroenterologu, lai veiktu detalizētāku novērtējumu.',
    },
    footer: {
      email: 'info@foodid.lv',
    },
    button: {
      showAll: 'Rādīt visu',
      showLess: 'Rādīt mazāk',
    },
  },
  years: '{vecums} m.',
  testResults: {
    protocolNumber: 'Testa ziņojums Nr.',
    testTitle: 'Pētījuma nosaukums:',
    nameSurname: 'Vārds un uzvārds:',
    gender: 'Dzimums:',
    birthDate: 'Dzimšanas datums:',
    sampleDateTime: 'Paraugu ņemšanas datums un laiks:',
    laboratoryDateTime: 'Datums un laiks, kad paraugs saņemts laboratorijā:',
    testDateTime: 'Pārējā pētījuma datums un laiks:',
    resultsDateTime: 'Rezultātu datums un laiks:',
    sampleTypes: 'Parauga veids:',
    sampleCode: 'Koda paraugs:',
    method: 'Metode:',
    testPerformedBy: 'Pētījumu veica:',
    testResultsConfirmed: 'Rezultātus apstiprināja:',
  },
  doctorSelection: {
    pickerPlaceholder: 'Ārsta izvēle...',
    doNotWantToShare: 'Es nevēlos dalīties informācijā ar ārstu',
  },
  questionnaire: {
    success: {
      title: '',
    },
    bloodCollectionNote: 'Kraujo mėginio paėmimas jau įskaičiuotas į tyrimo kainą',
  },
};
