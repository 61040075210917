import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import tw, { styled } from 'twin.macro';
import { LockClosedIcon } from '@heroicons/react/solid';
import { up } from 'styled-breakpoints';
import classNames from 'classnames';

import CommonAnimatedLoader from '../common/animated/loader';
import { FormCheckout } from '../../entities/forms/checkout';
import { parameters, strings } from '../../localization/strings';
import { Bank } from '../../entities/bank';
import IMAGES from '../../assets/images';
import CommonButtonDefault from '../common/buttons/default';
import Heading3 from '../common/headings/heading3';
import StyledLabel from '../common/form/styled-label';
import StyledError from '../common/form/styled-error';
import AlertPrimary from '../common/alerts/alert-primary';
import { currency } from '../../services/formatter/currency';
import CheckoutDiscountFormComponent from './discount-form';
import { Coupon } from '../../entities/coupon';
import CheckoutTotalsComponent from './totals';
import { ShortAnswerProduct } from '../../entities/short-answer';
import { useSite } from '../../hooks/use-site';

const BankButton = styled.button<{ active: boolean }>`
  height: 90px;
  ${tw`flex items-center justify-center p-2 p-5 bg-white text-gray-100 rounded-lg border border-content-400 hover:border-blue-400`}

  ${(props) => props.active && tw`border-blue-500`}

  ${up('sm')} {
    height: 130px;
  }
`;

const BankImage = styled.img`
  max-height: 30px;
  max-width: 90%;
  margin: 0 auto;
  display: inline-block;

  ${up('md')} {
    max-height: 80px;
    max-width: 100%;
  }
`;

const getBanks = () => {
  return [
    {
      identifier: 'vb2',
      title: 'SEB Bankas',
      image: IMAGES.BANKS.SEB,
    },
    {
      identifier: 'hanza',
      title: 'Swedbank',
      image: IMAGES.BANKS.SWEDBANK,
    },
    {
      identifier: 'parex',
      title: 'Citadele',
      image: IMAGES.BANKS.CITADELE,
    },
    {
      identifier: 'nord',
      title: 'Luminor',
      image: IMAGES.BANKS.LUMINOR,
    },
    {
      identifier: 'mb',
      title: 'Medicinos bankas',
      image: IMAGES.BANKS.MEDICINOS,
    },
    {
      identifier: 'sb',
      title: 'Šiaulių bankas',
      image: IMAGES.BANKS.SIAULIU,
    },
    {
      identifier: 'card',
      title: 'Payment cards',
      image: IMAGES.BANKS.CARD,
    },
  ];
};

const getBanksPl = () => {
  return [
    {
      identifier: 'pl_przelewy24',
      title: 'Banks of Poland',
      image: IMAGES.BANKS.PL_PRZELEWY24,
    },
    {
      identifier: 'pl_blik',
      title: 'BLIK',
      image: IMAGES.BANKS.BLIK,
    },
    {
      identifier: 'card',
      title: 'Payment cards',
      image: IMAGES.BANKS.CARD,
    },
  ];
};

interface ComponentProps {
  isFailed?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  personCode?: string;
  childCode?: string;
  preselectBank?: string;
  isChild?: boolean;
  age?: number;
  hasPurchased?: boolean;
  product: ShortAnswerProduct;
  extraProduct?: ShortAnswerProduct;
  onComplete: (data: FormCheckout) => void;
}

function CheckoutFormComponent({
  isFailed,
  firstName,
  lastName,
  email,
  phone,
  preselectBank,
  product,
  extraProduct,
  onComplete,
  age,
}: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [discount, setDiscount] = useState<Coupon>();
  const price = extraProduct?.price ? Number(extraProduct.price) + Number(product.price) : product.price;
  const [total, setTotal] = useState<number>(price);
  const bankHeaderRef = useRef<HTMLDivElement>(null);
  const { locale } = useSite();

  const { register, handleSubmit, setValue, setError, clearErrors, watch, formState } = useForm<FormCheckout>();

  const isResearch =
    product.id === parameters().product.researchId || product.id === parameters().product.freeScreenerAsitId;

  const onSubmit: SubmitHandler<FormCheckout> = async (data) => {
    try {
      if (!data.bank && total > 0) {
        setError('bank', { message: strings().error.bank.required });
      } else {
        setIsLoading(true);
        const formData = data;

        if (firstName) {
          formData.firstName = firstName;
        }

        if (lastName) {
          formData.lastName = lastName;
        }

        if (email) {
          formData.email = email;
        }

        if (phone) {
          formData.phone = phone;
        }

        formData.isResearch = isResearch;
        await onComplete(formData);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setError('common', { message: (e as Error).message });
    }
  };

  let banks: Bank[] = getBanks();

  if (locale === 'pl') {
    banks = getBanksPl();
  }

  const onSelectBank = (selected: Bank) => {
    clearErrors('bank');
    setValue('bank', selected.identifier);
  };

  const onChangeDiscountCode = (code?: Coupon) => {
    setValue('discountCode', code?.code || '');
    setDiscount(code);
    if (code) {
      setTotal(
        code.amountExact
          ? Math.max(0, price - code.amountExact)
          : Math.round(price * (100 - code.amountPercentage)) / 100,
      );
    } else {
      setTotal(product.price);
    }
  };

  register('bank', {
    validate: {
      required: (value) => {
        return !!value || Math.round(total) === 0;
      },
    },
  });

  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'InitiateCheckout');
      window.fbq('track', 'CheckoutStart');
    }

    window.dataLayer.push({
      event: 'InitiateCheckout',
    });
    window.dataLayer.push({
      event: 'CheckoutStart',
    });
  }, []);

  useEffect(() => {
    if (Object.keys(formState.errors).length === 1 && formState.errors.bank && bankHeaderRef.current) {
      window.scrollTo({ behavior: 'smooth', top: bankHeaderRef.current.offsetTop - 10 });
    }
  }, [formState]);

  const button = !isResearch ? strings().button.completedOrder : strings().button.signOrder;

  return (
    <main className="lg:min-h-full lg:overflow-hidden lg:flex lg:flex-row-reverse">
      {isLoading && <CommonAnimatedLoader />}
      <h1 className="sr-only">{strings().checkout.titleBilling}</h1>

      {/* Order summary */}
      {!isResearch && (
        <section aria-labelledby="summary-heading" className="bg-gray-50 w-full lg:max-w-md flex-col lg:flex pt-10">
          <h2 id="order-heading" className="sr-only">
            {strings().checkout.titleYourOrder}
          </h2>

          <ul className="divide-gray-200 px-6 pt-5 lg:pt-0">
            <li className="flex py-6 space-x-6">
              <img
                src={IMAGES.PRODUCTS.ALLERGO_EXPERT}
                alt={strings().products[product.key].title}
                className="flex-none w-20 h-20 sm:w-40 sm:h-40 object-center object-cover bg-gray-200 rounded-md"
              />
              <div className="flex flex-col space-y-4">
                <div className="text-sm font-medium space-y-1">
                  <h3 className="text-gray-900 font-bold">{product.title}</h3>
                  <p className="text-gray-900">{currency(product.price, locale)}</p>
                </div>
                {!!extraProduct && (
                  <div className="text-sm font-medium space-y-1">
                    <h3 className="text-gray-900 font-bold">{extraProduct.title}</h3>
                    <p className="text-gray-900">{currency(extraProduct.price, locale)}</p>
                  </div>
                )}
              </div>
            </li>
          </ul>

          <div className="sticky bottom-0 flex-none bg-gray-50 border-t border-gray-200 p-6">
            <CheckoutDiscountFormComponent onChange={onChangeDiscountCode} productId={product.id} />
            <CheckoutTotalsComponent subtotal={price} discount={discount} total={total} />
          </div>
        </section>
      )}
      {/* Checkout form */}
      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pt-7 md:pt-12 lg:mt-36 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24"
      >
        <div className="max-w-lg mx-auto">
          {isFailed && (
            <div className="mb-8">
              <AlertPrimary
                type="failed"
                title={strings().checkout.failed.title}
                text={strings().checkout.failed.description}
              />
            </div>
          )}

          {isResearch && age && age > 12 && (
            <Heading3 className="text-center">{strings().checkout.titleResearch}</Heading3>
          )}
          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-y-6 gap-x-4">
              {isResearch && age && age < 12 && (
                <>
                  <div className="col-span-full">
                    <Heading3>{strings().checkout.titleSpouseContactInfo}</Heading3>
                  </div>
                  <div className="mt-2 text-sm text-content-600 col-span-full">
                    {strings().checkout.explanationTextInfoForChildsResearch}
                  </div>
                  <div className="col-span-full">
                    <StyledLabel htmlFor="spouseName">{strings().form.spouseName}</StyledLabel>
                    <div className="mt-1">
                      <input
                        {...register('spouseFirstName', { required: true })}
                        type="text"
                        id="spouseFirstName"
                        name="spouseFirstName"
                        className={classNames(
                          'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
                          {
                            'border-red-500': formState.errors.spouseFirstName,
                          },
                        )}
                      />
                      {formState.errors.spouseFirstName && (
                        <StyledError>{strings().error.firstName.required}</StyledError>
                      )}
                    </div>
                  </div>
                  <div className="col-span-full">
                    <StyledLabel htmlFor="spouseSurname">{strings().form.spouseSurname}</StyledLabel>
                    <div className="mt-1">
                      <input
                        {...register('spouseLastName', { required: true })}
                        type="text"
                        id="spouseLastName"
                        name="spouseLastName"
                        className={classNames(
                          'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
                          {
                            'border-red-500': formState.errors.spouseLastName,
                          },
                        )}
                      />
                      {formState.errors.spouseLastName && (
                        <StyledError>{strings().error.lastName.required}</StyledError>
                      )}
                    </div>
                  </div>
                  <div className="col-span-full">
                    <StyledLabel htmlFor="spouseEmail">{strings().form.spouseEmail}</StyledLabel>
                    <div className="mt-1">
                      <input
                        {...register('spouseEmail', { required: true })}
                        type="email"
                        id="spouseEmail"
                        name="spouseEmail"
                        className={classNames(
                          'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
                          {
                            'border-red-500': formState.errors.email,
                          },
                        )}
                      />
                      {formState.errors.spouseEmail && <StyledError>{strings().error.email.required}</StyledError>}
                    </div>
                  </div>
                </>
              )}
            </div>

            {total > 0 && (
              <>
                <Heading3 ref={bankHeaderRef} className="mt-10 md:mt-16">
                  {strings().checkout.titleChooseBank}
                </Heading3>
                {formState.errors.bank && <StyledError>{strings().error.bank.required}</StyledError>}
                <div className="grid grid-cols-2 gap-6 mt-6">
                  {banks.map((bank) => (
                    <BankButton
                      className="group"
                      active={bank.identifier === watch('bank', preselectBank)}
                      type="button"
                      onClick={() => onSelectBank(bank)}
                      key={bank.identifier}
                    >
                      <BankImage
                        className={`group-hover:grayscale-0 group-hover:opacity-100 ${
                          bank.identifier === watch('bank', preselectBank)
                            ? `grayscale-0 opacity-100`
                            : `grayscale opacity-40`
                        }`}
                        src={bank.image}
                        alt={bank.title}
                      />
                    </BankButton>
                  ))}
                </div>
              </>
            )}

            {formState.errors.common && <StyledError>{formState.errors.common.message}</StyledError>}

            <CommonButtonDefault
              onClick={() => clearErrors('common')}
              type="submit"
              primary
              className="block w-full mt-6"
            >
              {total > 0 ? `${strings().button.pay} ${currency(total, locale)}` : button}
            </CommonButtonDefault>

            <div className="flex justify-center text-sm font-medium text-gray-500 mt-6">
              <LockClosedIcon className="w-5 h-5 text-gray-400 mr-1.5 flex-shrink-0" aria-hidden="true" />
              {isResearch ? (
                <div>{strings().checkout.SigningOfDocumentsDokobit}</div>
              ) : (
                <div>
                  {strings().checkout.checkboxPayseraText}{' '}
                  <a
                    className="underline"
                    href={parameters().paymentTermsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {strings().checkout.checkboxPayseraTerms}
                  </a>
                  {strings().checkout.checkboxPayseraAgreement}
                </div>
              )}
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

CheckoutFormComponent.defaultProps = {
  isFailed: false,
  isChild: false,
  hasPurchased: false,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  preselectBank: '',
  personCode: '',
  childCode: '',
  age: 0,
  extraProduct: 0,
};

export default CheckoutFormComponent;
