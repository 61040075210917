import React, { useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { pl as polish, lt as lithuanian } from 'date-fns/locale';

import { strings } from '../../localization/strings';
import StyledLabel from '../common/form/styled-label';
import CommonAnimatedLoader from '../common/animated/loader';
import StyledError from '../common/form/styled-error';
import CommonButtonDefault from '../common/buttons/default';
import Heading2 from '../common/headings/heading2';
import { FormPatient } from '../../entities/forms/patient';
import { createPatient } from '../../services/api/patients';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';
import { useSite } from '../../hooks/use-site';

interface ComponentProps {
  name?: string;
  lastName?: string;
  isChild?: boolean;
  age?: number;
  token: string;
  customerId?: number;
  productId?: number;
}

function PatientsFormComponent({ token, name, lastName, age, isChild, productId }: ComponentProps) {
  const genderOptions: { value: number; label: string }[] = [
    { value: 0, label: strings().form.genderSelect },
    { value: 1, label: strings().form.genderMale },
    { value: 2, label: strings().form.genderFemale },
  ];

  const today = new Date();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cookies = new Cookies();
  const { hasPersonCode, hasPresale, hasBirthDate, locale } = useSite();

  const hash = cookies.get(COOKIE_NAME_ANSWER);

  const { register, control, handleSubmit, setError, clearErrors, formState } = useForm<FormPatient>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormPatient> = async (data) => {
    try {
      setIsLoading(true);
      let birthDateFormated;

      if (data.birthDate) {
        birthDateFormated = format(new Date(data.birthDate), 'yyyy-MM-dd');
      }

      const formData: FormPatient = { ...data, age, isChild, birthDate: birthDateFormated };

      const res = await createPatient(formData, token);

      if (res && hash) {
        if (window.fbq) {
          window.fbq('trackCustom', 'PatientDetails');
        }

        window.dataLayer.push({
          event: 'PatientDetails',
        });

        const patientId = Number(res.id);

        const productIds = [12, 13, 15, 17];

        if (productId && productIds.includes(productId) && hasPresale) {
          navigate(`/questionnaire/select-review/${hash}/${patientId}`);
        } else if (patientId && hash) {
          navigate(`/payments/checkout/${hash}/${patientId}`);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError('personCode', { message: (e as Error).message });
    }
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}

      {isChild ? (
        <Heading2 className="mb-6">{strings().createPatientChild.title}</Heading2>
      ) : (
        <Heading2 className="mb-6">{strings().createPatient.title}</Heading2>
      )}
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-12 gap-y-6 gap-x-4">
          <div className="col-span-full">
            <StyledLabel htmlFor="name">{strings().form.name}</StyledLabel>
            <div className="mt-1">
              <input
                {...register('name', { required: true })}
                defaultValue={name}
                type="text"
                id="name"
                name="name"
                autoComplete="given-name"
                className={classNames(
                  'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
                  {
                    'border-red-500': formState.errors.name,
                  },
                )}
              />
              {formState.errors.name && <StyledError>{strings().error.firstName.required}</StyledError>}
            </div>
          </div>
          <div className="col-span-full">
            <StyledLabel htmlFor="surname">{strings().form.surname}</StyledLabel>
            <div className="mt-1">
              <input
                {...register('lastName', { required: true })}
                defaultValue={lastName}
                type="text"
                id="lastName"
                name="lastName"
                autoComplete="family-name"
                className={classNames(
                  'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
                  {
                    'border-red-500': formState.errors.lastName,
                  },
                )}
              />
              {formState.errors.lastName && <StyledError>{strings().error.lastName.required}</StyledError>}
            </div>
          </div>
          {hasPersonCode && (
            <div className="col-span-full">
              <StyledLabel htmlFor="personCode">{strings().form.personCode}</StyledLabel>
              <div className="mt-1">
                <input
                  {...register('personCode', { required: true, minLength: 11, maxLength: 11 })}
                  type="text"
                  id="personCode"
                  name="personCode"
                  className={`block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                    formState.errors.personCode && `border-red-500`
                  } `}
                />
                {formState.errors.personCode && formState.errors.personCode?.type === 'minLength' && (
                  <StyledError>{strings().error.personCode.length}</StyledError>
                )}
                {formState.errors.personCode && formState.errors.personCode?.type === 'maxLength' && (
                  <StyledError>{strings().error.personCode.length}</StyledError>
                )}
                {formState.errors.personCode?.type === 'required' && (
                  <StyledError>{strings().error.personCode.required}</StyledError>
                )}
                {formState.errors.personCode?.message && (
                  <StyledError>{strings().error.personCode.incorrect}</StyledError>
                )}
              </div>
              <div className="mt-2 text-sm text-content-600">
                {strings().checkout.explanationTextInfoForRemoteConsultation}
              </div>
            </div>
          )}
          {hasBirthDate && (
            <div className="col-span-full">
              <StyledLabel htmlFor="birthDate">{strings().form.birthDate}</StyledLabel>

              <div className="mt-1">
                <Controller
                  control={control}
                  name="birthDate"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText={strings().form.placeholderBirthDate}
                      selected={field.value ? new Date(field.value) : undefined}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={today}
                      locale={locale === 'pl' ? polish : lithuanian}
                      dateFormat="yyyy-MM-dd"
                      className={classNames(
                        'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
                        {
                          'border-red-500': formState.errors.birthDate,
                        },
                      )}
                    />
                  )}
                />

                {formState.errors.birthDate && <StyledError>{strings().error.birthDate.required}</StyledError>}
                <div className="mt-2 text-sm text-content-600">{strings().form.birthDateFormat}</div>
              </div>
            </div>
          )}
          <div className="col-span-full">
            <StyledLabel htmlFor="genderId">{strings().form.gender}</StyledLabel>

            <Controller
              control={control}
              name="genderId"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  classNamePrefix="react-select"
                  options={genderOptions}
                  className={`block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                    formState.errors.genderId ? `border-red-500` : `border-gray-300`
                  } `}
                  placeholder={strings().form.genderPickerPlaceholder}
                  onChange={(e) => {
                    field.onChange(e?.value);
                  }}
                />
              )}
            />
            {formState.errors.genderId && <StyledError>{strings().error.gender.required}</StyledError>}
          </div>
          {isChild && (
            <div className="col-span-full">
              <div className="flex space-x-2">
                <div className="flex items-center h-5">
                  <input
                    {...register('agreeChildResponsibility', { required: true })}
                    id="agreeChildResponsibility"
                    name="agreeChildResponsibility"
                    type="checkbox"
                    className="h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500 checked:bg-blue-900 checked:hover:bg-blue-900"
                  />
                </div>
                <label htmlFor="agreeChildResponsibility" className="text-sm text-gray-900">
                  {strings().checkout.checkboxAgreeChildResponsibility}
                </label>
              </div>
              {formState.errors.agreeChildResponsibility && (
                <StyledError>{strings().error.agreeChildResponsibility.required}</StyledError>
              )}
            </div>
          )}
          <div className="col-span-full">
            <div className="flex space-x-2">
              <div className="flex items-center h-5">
                <input
                  {...register('agreeResearch', { required: false })}
                  id="agreementForScienceResearch"
                  name="agreeResearch"
                  type="checkbox"
                  className="h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500 checked:bg-blue-900 checked:hover:bg-blue-900"
                />
              </div>
              <label htmlFor="agreementForScienceResearch" className="text-sm text-gray-900">
                {strings().form.agreementForScienceResearch}
              </label>
            </div>
            {formState.errors.agreeResearch && (
              <StyledError>{strings().error.agreementForScienceResearch.required}</StyledError>
            )}
          </div>
        </div>

        {formState.errors.common && <StyledError>{formState.errors.common.message}</StyledError>}

        <CommonButtonDefault onClick={() => clearErrors('common')} type="submit" primary className="block mt-10">
          {strings().button.createPatient}
        </CommonButtonDefault>
      </form>
    </>
  );
}

PatientsFormComponent.defaultProps = {
  isChild: false,
  name: '',
  lastName: '',
  age: '',
  customerId: '',
  productId: '',
};

export default PatientsFormComponent;
