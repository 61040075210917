import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import classNames from 'classnames';

import * as ordersApi from '../../../services/api/orders';
import { AllergensAnswer, AllergensAnswerAllergen } from '../../../entities/allergens-answer';
import LegendBlock from '../../../components/questionnaire/results/legend';
import { strings } from '../../../localization/strings';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import { useAuth } from '../../../hooks/use-auth';
import { FullAnswer } from '../../../entities/full-answer';
import { useSite } from '../../../hooks/use-site';
import Heading2 from '../../../components/common/headings/heading2';

export default function ProfileResultsAllergensPageComponent() {
  const { token, tokenAdmin } = useAuth();
  const { hash } = useParams();
  const [allergenGroups, setAllergenGroups] = useState<AllergensAnswer>();
  const { answer } = useOutletContext<{ answer: FullAnswer; isPreview: boolean }>();
  const { isfoodId, isAllergomedica, locale } = useSite();

  useEffect(() => {
    const fetchAnswer = async () => {
      if (hash) {
        const res = await ordersApi.getAllergens(token || tokenAdmin, hash, locale === 'en' ? locale : undefined);

        setAllergenGroups(res);
      }
    };

    fetchAnswer();
  }, [hash, token, tokenAdmin, locale]);

  const createAllergens = (allergens: AllergensAnswerAllergen[], isScreener: boolean): AllergensAnswerAllergen[] => {
    if (isScreener) {
      let allergen = allergens[0];

      /**
       *group allergens and find maximum value for it* */
      for (let i = 0; i < allergens.length; i += 1) {
        if (allergens[i].strength > allergen.strength) {
          allergen = allergens[i];
        }
      }

      return [allergen];
    }

    return allergens;
  };

  const showBiochemicalDesignation =
    answer.productKey !== 'FREE_SCREENER' &&
    answer.productKey !== 'SCREENER' &&
    answer.productKey !== 'FREE_SCREENER_ASIT' &&
    answer.productKey !== 'FOOD_ID';

  const getStrengthWord = (strength: number) => {
    switch (true) {
      case strength >= 3:
        return strings().legend.noNum.senseHigh;

      case strength === 2:
        return strings().legend.noNum.senseMedium;

      case strength === 1:
        return strings().legend.noNum.senseLow;

      default:
        return strings().legend.noNum.senseNone;
    }
  };

  return (
    <>
      {!allergenGroups && (
        <div className="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-10 print:max-w-none">
          <div className="flex items-center justify-center mt-20">
            <CommonAnimatedSpinner />
          </div>
        </div>
      )}

      {allergenGroups && (
        <>
          {isfoodId && (
            <div className="bg-primary py-10 md:py-14 mb-10">
              <div className="container max-w-4xl px-4 mx-auto">
                <div className="text-white text-2xl sm:text-4xl lg:text-5xl print:!text-lg leading-snug text-center ">
                  {strings().FOOD_ID.test_answer.profileAllergens.weHaveEvaluated}

                  <div className="print:inline print:ml-1 font-bold">
                    {answer.allergensCount} {strings().FOOD_ID.test_answer.profileAllergens.allergensTotal}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-10 print:max-w-none">
            <div>
              {isAllergomedica && (
                <div className="mx-auto md:w-2/4 text-center text-3xl md:text-5xl mt-16 pb-12 leading-tight print:mt-5 print:pb-0 print:!text-lg">
                  {strings().profileAllergens.weHaveEvaluated}
                  <div className="print:inline print:ml-1 font-bold">
                    {strings().products[answer.productKey].allergens.replace('{allergensCount}', answer.allergensCount)}{' '}
                    {strings().profileAllergens.allergensTotal}
                    {/* {pluralize(Number(answer.allergensCount), strings().profileAllergens.allergensTotal)} */}
                  </div>
                </div>
              )}
              {answer.info && (
                <div className="space-y-1 mb-10">
                  <div className="mb-3 md:mb-5">
                    <b>{strings().testResults.protocolNumber}</b> {answer.info.protocol_number}
                  </div>
                  <div>
                    <b>{strings().testResults.nameSurname}</b> {answer.info.patient}
                  </div>
                  <div>
                    <b>{strings().testResults.birthDate}</b> {answer.info.birth_date}
                  </div>
                  <div>
                    <b>{strings().testResults.gender}</b> {answer.info.gender}
                  </div>
                </div>
              )}
              <div className="print:!text-xs">
                <LegendBlock hasNumValue={isAllergomedica} />
              </div>
              <Heading2 className="mt-7 md:mt-10 print:!mt-3">
                {strings().profileAllergens.title.researchOverview}
              </Heading2>

              <div className="border rounded-lg md:rounded-xl border-gray-200 bg-white mt-7 md:mt-10 print:!mt-3 print:!rounded-none md:grid grid-cols-2 print:!text-xs overflow-hidden">
                <div className="px-3 py-4 md:px-5 print:!hidden border-gray-200 border-b border-r md:grid grid-cols-2 bg-content-300 font-bold items-center">
                  <div>{strings().profileAllergens.tableOverview.group}</div>
                  <div className="text-right">{strings().profileAllergens.tableOverview.amount}</div>
                </div>
                <div className="px-3 md:px-5 print:!hidden border-gray-200 border-b border-r md:grid grid-cols-2 bg-content-300 font-bold items-center">
                  <div>{strings().profileAllergens.tableOverview.group}</div>
                  <div className="text-right">{strings().profileAllergens.tableOverview.amount}</div>
                </div>
                {allergenGroups.airwayGroups?.map((group) => (
                  <div
                    className={classNames(
                      'p-3 md:px-5 print:!p-1 border-b border-gray-200 grid grid-cols-3 odd:border-r',
                      {
                        'bg-senseHigh-50': group.sensitiveAllergensMaxStrength >= 3,
                        'bg-senseMedium-50': group.sensitiveAllergensMaxStrength === 2,
                        'bg-senseLow-50': group.sensitiveAllergensMaxStrength === 1,
                      },
                    )}
                    key={`group-${group.id}`}
                  >
                    <div className="col-span-2">{group.title}</div>
                    <div className="text-right">{group.sensitiveAllergensCount}</div>
                  </div>
                ))}
                {allergenGroups.groups?.map((group) => (
                  <div
                    className={classNames(
                      'p-3 md:px-5 print:!p-1 border-b border-gray-200 grid grid-cols-3 md:odd:border-r',
                      {
                        'bg-senseHigh-50': group.sensitiveAllergensMaxStrength >= 3,
                        'bg-senseMedium-50': group.sensitiveAllergensMaxStrength === 2,
                        'bg-senseLow-50': group.sensitiveAllergensMaxStrength === 1,
                      },
                    )}
                    key={`group-${group.id}`}
                  >
                    <div className="col-span-2">{group.title}</div>
                    <div className="text-right">{group.sensitiveAllergensCount}</div>
                  </div>
                ))}
              </div>
              {/* full result */}
              <Heading2 className="mt-7 md:mt-10 print:!mt-3">
                {strings().profileAllergens.title.researchResults}s
              </Heading2>
              {/* įkvepiami alergenai */}
              {allergenGroups.airwayGroups && allergenGroups.airwayGroups.length > 0 && (
                <div className="bg-white border-gray-200 border rounded-lg md:rounded-xl overflow-scroll md:overflow-hidden mt-7 md:mt-10 print:!mt-3 print:!rounded-none print:!text-xs">
                  <table className="w-full border-collapse">
                    <thead className="bg-content-300">
                      <tr className="">
                        <th className="text-left p-3 md:p-5 print:!p-1">{strings().profileAllergens.table.allergen}</th>
                        <th className="text-left p-3 md:p-5 print:!p-1">
                          {strings().profileAllergens.table.component}
                        </th>
                        <th className="text-left p-3 md:p-5 print:!p-1">
                          {strings().profileAllergens.table.biochemicalDesignation}
                        </th>
                        {showBiochemicalDesignation ? (
                          <th className="text-left p-3 md:p-5 print:!p-1">{strings().profileAllergens.table.value}</th>
                        ) : (
                          <th className="text-left p-3 md:p-5 print:!p-1">
                            {strings().profileAllergens.table.strength}
                          </th>
                        )}
                      </tr>
                    </thead>
                    {allergenGroups.airwayGroups.map((sourceGroup) => (
                      <tbody key={sourceGroup.id}>
                        <tr className="border-t border-content-500">
                          <td className="p-3 md:px-5 pt-6 print:!p-1" colSpan={4}>
                            <b>{sourceGroup.title}</b>
                          </td>
                        </tr>
                        {sourceGroup.sources.map((source) => {
                          return createAllergens(source.allergens, false).map((allergen) => (
                            <tr
                              className={classNames('border-t border-content-500', {
                                'bg-senseHigh-50': allergen.strength >= 3,
                                'bg-senseMedium-50': allergen.strength === 2,
                                'bg-senseLow-50': allergen.strength === 1,
                              })}
                              key={`s-${allergen.id}-${source.id}-${allergen.analyzerId}`}
                            >
                              <td className="p-3 md:px-5 print:!p-1">{source.title}</td>
                              <td className="p-3 md:px-5 print:!p-1">{allergen.componentCode}</td>
                              <td className="p-3 md:px-5 print:!p-1">{allergen.biochemicalDesignation}</td>
                              <td className="p-3 md:px-5 print:!p-1 lg:w-[230px]">
                                {showBiochemicalDesignation ? (
                                  allergen.valueText || (allergen.strength < 1 ? 0 : allergen.value)
                                ) : (
                                  <>
                                    {allergen.strength >= 3 && <span>{strings().legend.noNum.senseHigh}</span>}{' '}
                                    {allergen.strength === 2 && <span>{strings().legend.noNum.senseMedium}</span>}{' '}
                                    {allergen.strength === 1 && <span>{strings().legend.noNum.senseLow}</span>}{' '}
                                    {allergen.strength === 0 && <span>{strings().legend.noNum.senseNone}</span>}
                                    {/* {allergen.valueText || (allergen.strength < 1 ? 0 : allergen.value)} */}
                                  </>
                                )}
                              </td>
                            </tr>
                          ));
                        })}
                      </tbody>
                    ))}
                  </table>
                </div>
              )}
              {/* įkvepiami alergenai ends */}

              <div className="bg-white border-gray-200 border rounded-lg md:rounded-xl overflow-scroll md:overflow-hidden mt-7 md:mt-10 print:!mt-3 print:!rounded-none print:!text-xs">
                <table className="w-full border-collapse">
                  <thead className="bg-content-300">
                    <tr className="">
                      <th className="text-left p-3 md:p-5 print:!p-1">
                        {isAllergomedica
                          ? strings().profileAllergens.table.allergen
                          : strings().FOOD_ID.test_answer.profileAllergens.table.allergen}
                      </th>

                      {showBiochemicalDesignation ? (
                        <>
                          <th className="text-left p-3 md:p-5 print:!p-1">
                            {isAllergomedica
                              ? strings().profileAllergens.table.component
                              : strings().FOOD_ID.test_answer.profileAllergens.table.allergen}
                          </th>
                          <th className="text-left p-3 md:p-5 print:!p-1">
                            {strings().profileAllergens.table.biochemicalDesignation}
                          </th>
                          <th className="text-left p-3 md:p-5 print:!p-1">{strings().profileAllergens.table.value}</th>
                        </>
                      ) : (
                        <th className={`${isAllergomedica ? 'text-left' : 'text-right'} p-3 md:p-5 print:!p-1`}>
                          {isAllergomedica
                            ? strings().profileAllergens.table.strength
                            : strings().FOOD_ID.test_answer.profileAllergens.table.strength}
                        </th>
                      )}
                    </tr>
                  </thead>
                  {allergenGroups.groups.map((sourceGroup) => (
                    <tbody key={sourceGroup.id}>
                      <tr className="border-t border-content-500">
                        <td className="p-3 md:px-5 pt-6 print:!p-1" colSpan={4}>
                          <b>{sourceGroup.title}</b>
                        </td>
                      </tr>
                      {sourceGroup.sources.map((source) => {
                        return createAllergens(source.allergens, !showBiochemicalDesignation).map((allergen) => (
                          <tr
                            className={classNames('border-t border-content-500', {
                              'bg-senseHigh-50': allergen.strength >= 3,
                              'bg-senseMedium-50': allergen.strength === 2,
                              'bg-senseLow-50': allergen.strength === 1,
                            })}
                            key={`s-${allergen.id}-${source.id}-${allergen.analyzerId}`}
                          >
                            <td className="p-3 md:px-5 print:!p-1">{source.title}</td>
                            {showBiochemicalDesignation ? (
                              <>
                                <td className="p-3 md:px-5 print:!p-1">{allergen.componentCode}</td>
                                <td className="p-3 md:px-5 print:!p-1">{allergen.biochemicalDesignation}</td>
                                <td className="p-3 md:px-5 print:!p-1">
                                  {allergen.valueText || (allergen.strength < 1 ? 0 : allergen.value)}
                                </td>
                              </>
                            ) : (
                              <td className={`${isfoodId && 'text-right'} p-3 md:px-5 print:!p-1`}>
                                {allergen.strength >= 3 && <span>{strings().legend.noNum.senseHigh}</span>}{' '}
                                {allergen.strength === 2 && <span>{strings().legend.noNum.senseMedium}</span>}{' '}
                                {allergen.strength === 1 && <span>{strings().legend.noNum.senseLow}</span>}{' '}
                                {allergen.strength === 0 && <span>{strings().legend.noNum.senseNone}</span>}
                                {/* {allergen.valueText || (allergen.strength < 1 ? 0 : allergen.value)} */}
                              </td>
                            )}
                          </tr>
                        ));
                      })}
                    </tbody>
                  ))}
                </table>
              </div>
              {answer.resultsUrl && (
                <div className="text-sm text-content-700 text-center mt-3">
                  {strings().profileAllergens.footnotes.prefix}
                  &nbsp;
                  <a className="text-content-700" href={answer.resultsUrl} target="_blank" rel="noreferrer noopener">
                    {strings().profileAllergens.footnotes.link}
                  </a>
                  &nbsp;
                  {strings().profileAllergens.footnotes.suffix}
                </div>
              )}
            </div>
          </div>
          {answer.info && (
            <div className="bg-gray-50 print:!bg-white">
              <div className="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-10 print:max-w-none py-6">
                <div className="md:grid md:grid-cols-2 gap-3">
                  <div className="space-y-2">
                    <div>
                      <b>{strings().testResults.sampleDateTime}</b> {answer.info.sample_collected_at}
                    </div>
                    <div>
                      <b>{strings().testResults.laboratoryDateTime}</b> {answer.info.sample_arrived_at}
                    </div>
                    <div>
                      <b>{strings().testResults.testDateTime}</b> {answer.info.test_at}
                    </div>
                    <div>
                      <b>{strings().testResults.resultsDateTime}</b> {answer.info.results_at}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div>
                      <b>{strings().testResults.sampleTypes}</b> {answer.info.sample_type}
                    </div>
                    <div>
                      <b>{strings().testResults.sampleCode}</b> {answer.info.sample_code}
                    </div>
                    <div>
                      <b>{strings().testResults.method}</b> {answer.info.observation_method}
                    </div>
                    <div>
                      <b>{strings().testResults.testPerformedBy}</b> {answer.info.test_done_by}
                    </div>
                    <div>
                      <b>{strings().testResults.testResultsConfirmed}</b> {answer.info.results_confirmed_by}
                    </div>
                    <div>
                      <b>{strings().testResults.testTitle}</b> {answer.info.title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
