interface Parameters {
  [key: string]: any;
}

export const sharedParameters: Parameters = {
  isDevelopment: process.env.NODE_ENV === 'development',
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    websocketUrl: process.env.REACT_APP_WEBSOCKET_URL,
    websiteUrl: process.env.REACT_APP_WEBSITE_URL,
  },
  dokobit: {
    host: process.env.REACT_APP_DOKOBIT_HOST,
  },
  firstPageUrl: '/profile/messages',
  selectPatient: '/questionnaire/patient',
  createPatient: '/questionnaire/patient/create',
  features: {
    hasConsultation: false,
    hasPresale: false,
    hasPersonCode: false,
    hasBirthDate: true,
    hasDoctor: false,
    questionnaireAfterSale: true,
  },
  password: {
    minLength: 8,
  },
};

export const parametersValues: Parameters = {
  allergomedica: {
    lt: {
      appUrl: 'https://app.allergomedica.lt',
      appName: 'Allergomedica',
      sampleReportHash: 'e69a1764-c72d-4b0f-a4ab-41726bca28c6',
      researchSuccess: '/research/success',
      freeResearchUrl: 'https://allergomedica.lt/nemokamas-tyrimas',
      paymentTermsUrl: 'https://www.paysera.lt/v2/lt-LT/sutartys/mip-taisykles-2020',
      features: {
        hasConsultation: true,
        hasPersonCode: true,
        hasBirthDate: false,
        hasPresale: true,
        hasDoctor: true,
        questionnaireAfterSale: false,
      },
      product: {
        consultationId: 1,
        researchId: 4,
        stepsImmunotherapyId: 8,
        stepsImmunotherapyConsultationId: 9,
        immunotherapyProductId: 10,
        immunotherapyProductId6month: 14,
        freeScreenerAsitId: 11,
        allergyChipConsultationId: 12,
        allergyChipCommentId: 15,
        questionnaireAfterSale: false,
        repeatedConsultationId: 21,
      },
      textPage: {
        privacyPolicy: 'privatumo-politika',
        terms: 'paslaugu-teikimo-salygos',
      },
    },
    pl: {
      appUrl: 'https://app.allergomedica.pl',
      appName: 'Allergomedica',
      // sampleReportHash: 'e69a1764-c72d-4b0f-a4ab-41726bca28c6',
      // researchSuccess: '/research/success',
      // freeResearchUrl: 'https://allergomedica.lt/nemokamas-tyrimas',
      paymentTermsUrl: 'https://www.przelewy24.pl/regulamin',
      features: {
        hasPresale: true,
        hasBirthDate: true,
      },
      product: {
        consultationId: 1,
        researchId: 4,
        stepsImmunotherapyId: 8,
        stepsImmunotherapyConsultationId: 9,
        immunotherapyProductId: 10,
        immunotherapyProductId6month: 14,
        freeScreenerAsitId: 11,
        allergyChipConsultationId: 12,
        allergyChipCommentId: 15,
        repeatedConsultationId: 21,
      },
      textPage: {
        privacyPolicy: 'polityka-poufnosci-i-ochrony-danych-osobowych',
        terms: 'warunki-swiadczenia-uslug',
      },
    },
    en: {
      appUrl: 'https://app.allergomedica.lt',
      appName: 'Allergomedica',
      sampleReportHash: 'e69a1764-c72d-4b0f-a4ab-41726bca28c6',
      researchSuccess: '/research/success',
      freeResearchUrl: 'https://allergomedica.lt/nemokamas-tyrimas',
      paymentTermsUrl: 'https://www.paysera.lt/v2/lt-LT/sutartys/mip-taisykles-2020',
      features: {
        hasConsultation: true,
        hasPersonCode: true,
        hasBirthDate: false,
        hasPresale: true,
        hasDoctor: true,
        questionnaireAfterSale: false,
      },
      product: {
        consultationId: 1,
        researchId: 4,
        stepsImmunotherapyId: 8,
        stepsImmunotherapyConsultationId: 9,
        immunotherapyProductId: 10,
        immunotherapyProductId6month: 14,
        freeScreenerAsitId: 11,
        allergyChipConsultationId: 12,
        allergyChipCommentId: 15,
        repeatedConsultationId: 21,
      },
      textPage: {
        privacyPolicy: 'privatumo-politika',
        terms: 'paslaugu-teikimo-salygos',
      },
    },
    lv: {
      appUrl: 'https://app.allergomedica.lv',
      appName: 'Allergomedica',
      paymentTermsUrl: 'https://www.paysera.lt/v2/lt-LT/sutartys/mip-taisykles-2020',
      product: {
        // consultationId: 1,
        // researchId: 4,
        // stepsImmunotherapyId: 8,
        // stepsImmunotherapyConsultationId: 9,
        // immunotherapyProductId: 10,
        // immunotherapyProductId6month: 14,
        // freeScreenerAsitId: 11,
        // allergyChipConsultationId: 12,
        // allergyChipCommentId: 15,
      },
      textPage: {
        privacyPolicy: '',
        terms: '',
      },
    },
  },
  foodId: {
    lt: {
      appName: 'Food Id',
      appUrl: 'https://app.foodid.lt',
      paymentTermsUrl: 'https://www.paysera.lt/v2/lt-LT/sutartys/mip-taisykles-2020',
      features: {
        hasPresale: true,
        hasBirthDate: true,
        questionnaireAfterSale: true,
      },
      product: {
        foodIntolerance: 17,
      },
      textPage: {
        privacyPolicy: 'privatumo-politika-food-id',
        terms: 'paslaugu-teikimo-salygos-food-id',
      },
    },
    pl: {
      appName: 'Food Id',
      appUrl: 'https://app.foodid.pl',
      paymentTermsUrl: 'https://www.przelewy24.pl/regulamin',
      features: {
        hasPresale: true,
        hasBirthDate: true,
      },
      product: {
        foodIntolerance: 17,
        repeatedConsultationId: 21,
      },
      textPage: {
        privacyPolicy: 'food-id-polityka-poufnosci-i-ochrony-danych-osobowych',
        terms: 'food-id-warunki-swiadczenia-uslug',
      },
    },
    en: {
      appName: 'Food Id',
      appUrl: 'https://app.foodid.lt',
      paymentTermsUrl: 'https://www.paysera.lt/v2/lt-LT/sutartys/mip-taisykles-2020',
      product: {
        foodIntolerance: 17,
      },
      textPage: {
        privacyPolicy: 'privatumo-politika-food-id',
        terms: 'paslaugu-teikimo-salygos-food-id',
      },
    },
    lv: {
      appName: 'Food Id',
      appUrl: 'https://app.foodid.lv',
      paymentTermsUrl: 'https://www.paysera.lt/v2/lt-LT/sutartys/mip-taisykles-2020',
      product: {
        foodIntolerance: 17,
      },
      textPage: {
        privacyPolicy: '',
        terms: '',
      },
    },
  },
};
