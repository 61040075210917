import React from 'react';
import tw, { styled } from 'twin.macro';
import { useSwiper } from 'swiper/react';
import { only } from 'styled-breakpoints';

import { Question, QuestionType } from '../../../entities/question';
import { QuestionOption } from '../../../entities/question-option';
import CommonButtonQuestion from '../../common/buttons/question';
import CommonButtonDefault from '../../common/buttons/default';
import { strings } from '../../../localization/strings';

const QuestionOptionsContainer = styled.div`
  ${tw`flex flex-row -mx-2 flex-wrap flex-1`}
`;

const QuestionOptionContainer = styled.div<{ wrapTexts?: boolean }>`
  ${tw`px-2 pb-4 w-full md:w-auto md:inline-flex`};
  ${(props) => props.wrapTexts && `${tw`md:w-56 md:flex`}`};
  ${only('sm')} {
    flex: 0 0 50%;
  }
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-row md:pt-12 justify-end flex-1`}
`;

interface ComponentProps {
  question: Question;
  onSelect?: (option: QuestionOption) => void;
}

function QuestionnaireQuestionSingleComponent({ question, onSelect }: ComponentProps) {
  const swiper = useSwiper();

  const onGoBack = () => {
    window.scrollTo(0, 0);
    swiper.slidePrev();
  };

  const onSelectOption = (option: QuestionOption) => {
    if (onSelect) {
      onSelect(option);
    }
  };

  return (
    <div>
      <QuestionOptionsContainer>
        {question.options.map((option) => (
          <QuestionOptionContainer key={option.id} wrapTexts={question.wrap}>
            <CommonButtonQuestion wrapTexts={question.wrap} onClick={() => onSelectOption(option)} key={option.id}>
              <div
                dangerouslySetInnerHTML={{
                  __html: option.title,
                }}
              />
            </CommonButtonQuestion>
          </QuestionOptionContainer>
        ))}
      </QuestionOptionsContainer>
      {question.key === 'bloodCollectionType' && (
        <div className="text-gray-400 mt-2">{strings().questionnaire.bloodCollectionNote}</div>
      )}
      <ButtonsContainer>
        <CommonButtonDefault onClick={() => onGoBack()}>{strings().button.goBack}</CommonButtonDefault>
      </ButtonsContainer>
    </div>
  );
}

QuestionnaireQuestionSingleComponent.defaultProps = {
  onSelect: null,
};

export default QuestionnaireQuestionSingleComponent;
