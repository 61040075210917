export const strings = {
  upTo: 'dopóki',
  redirecting: 'Kierowany...',
  startingFrom: 'z',
  consultation: '+ konsultacja',
  written: '+ komentarz',
  error: {
    required: 'Konieczna jest akceptacja',
    RequiredFillOut: 'Do wypełnienia',
    quotaReached: {
      title: 'Przepraszamy, dziś nie ma bezpłatnych testów',
      description: 'Zapraszamy do spróbowania jutro',
    },
    feedback: {
      title: 'Przepraszamy, ale wystąpił błąd',
      description: 'Prosimy o pozostawienie oceny',
    },
    common: {
      title: 'Przepraszamy, ale wystąpił błąd',
      description: 'Spróbuj ponownie później',
    },
    pageNotFound: {
      title: 'Strona nie znaleziona',
      description: 'Sprawdź adres strony i spróbuj ponownie.',
      goHome: 'Wróć do początku',
    },
    fieldIsRequired: 'To pole jest wymagane',
    youMustSelect: 'Opcja',
    terms: {
      required: 'Zaakceptowanie regulaminu jest obowiązkowe',
    },
    agreeResearch: {
      required: 'Jeżeli nie wyrazisz zgody, nie będziemy mogli przeprowadzić badań i przedstawić Ci rekomendacji',
    },
    agreementForScienceResearch: {
      required:
        'Jeżeli nie wyrazisz zgody, nie będziemy mogli przeprowadzić badań ani przedstawić Ci naszej rekomendacji',
    },
    selectedPatientId: {
      required: 'Decyzja pacjenta jest obowiązkowa',
    },
    firstName: {
      required: 'Imię jest wymagane',
    },
    lastName: {
      required: 'Nazwisko jest wymagane',
    },
    email: {
      required: 'Adres e-mail jest wymagany',
      isUsed:
        'Użytkownik został już zarejestrowany pod tym adresem e-mail. Skorzystaj z formularza logowania lub użyj innego adresu e-mail.',
    },
    phone: {
      required: 'Telefon jest obowiązkowy',
    },
    bank: {
      required: 'Wybierz metodę płatności',
    },
    password: {
      minLength: 'Hasło musi mieć co najmniej {length} znaków',
      same: 'Hasła muszą być zgodne',
    },
    birthDate: {
      required: 'Wymagana jest data urodzenia',
    },
    gender: {
      required: 'Płeć jest obowiązkowa',
    },
    personCode: {
      required: 'Kod osobisty jest obowiązkowy',
      incorrect: 'Wpisz swój osobisty kod. Wpisany kod jest nieprawidłowy.',
      length: 'Musi mieć jedenaście znaków',
    },
    agreeRemoteConsultation: {
      required: 'Jeżeli nie wyrazisz zgody, nie będziemy mogli udzielić Ci konsultacji zdalnych',
    },
    agreeChildResponsibility: {
      required: 'Aby zarejestrować dziecko, musisz być osobą upoważnioną.',
    },
    agreeTerms: {
      required: 'Musisz zaakceptować warunki użytkowania',
    },
    YourChildCode: {
      required: 'Kod osobisty jest obowiązkowy',
    },
    address: {
      required: 'Podanie adresu jest obowiązkowe',
    },
    backendError: 'Wystąpił błąd. Spróbuj ponownie później.',
  },
  loading: {
    wait: 'Prosimy poczekać..',
    wait_until_check: 'Prosimy poczekać, aż otrzymamy potwierdzenie płatności z banku',
    wait_until_sign_check: 'Prosimy poczekać na otrzymanie potwierdzenia podpisania dokumentu',
  },
  button: {
    close: 'Zamknij',
    continue: 'Kontynuuj',
    start: 'Początek',
    goBack: 'Wróć',
    buy: 'Zakup',
    order: 'Zamów',
    understood: 'Rozumiem',
    pay: 'Płatność',
    completedOrder: 'Zamów',
    check: 'Sprawdź',
    login: 'Zaloguj sie',
    preview: 'Zobacz',
    createAccount: 'Utwórz konto',
    createPatient: 'Utwórz pacjenta',
    sendReminder: 'Wyślij przypomnienie',
    changePassword: 'Zmień hasło',
    signOrder: 'Zapisz się',
    send: 'wyślij',
    upload: 'Wgraj',
    complete: 'Skończ',
    pdf: 'Utwórz plik PDF',
    startQuestionnaire: 'Wypełnij kwestionariusz',
    download: 'Pobierać',
  },
  products: {
    CONSULTATION: {
      allergens: '0',
      title: 'Zdalna konsultacja alergologa',
      titleResearch: 'products.CONSULTATION.titleResearch',
      titleProduct6month: 'products.CONSULTATION.titleProduct6month',
      orderTitle: 'Zarezerwuj zdalną konsultację',
      description:
        'Wywiad obejmuje analizę objawów i prawdopodobieństwo, że są one spowodowane alergią. Przeprowadzane są testy i przygotowywany zostaje wstępny plan leczenia.',
      bullets: [
        'Ocena alergologa i pisemne zalecenia',
        'Możliwość zadania dodatkowych pytań',
        '20-minutowa rozmowa wideo z alergologiem',
      ],
    },
    FULL: {
      allergens: '295',
      title: 'Kompleksowy program testów alergicznych',
      titleResearch: 'products.FULL.titleResearch',
      titleProduct6month: 'products.FULL.titleProduct6month',
      orderTitle: 'Zamów szczegółowe badanie',
      description:
        'Badanie analizuje uczulenia na prawie 300 alergenów, co uwzględnia praktycznie wszystkie alergeny, które mogą powodować wystąpienie szybkich reakcji alergicznych.',
      bullets: [
        'Badanie krwi na 295 alergenów (+ do 10 testów potwierdzających)',
        'Ocena i zalecenia alergologa',
        'Szczegółowy opis profilu alergenowego',
        'Możliwość zadania dodatkowych pytań',
        '20-minutowa rozmowa wideo z alergologiem',
      ],
      review: {
        auto: {
          title: 'Zautomatyzowana ocena ankiety',
          price: '+0 €',
          description: 'Ocenimy, które alergeny mogą wywołać wymienione przez Ciebie objawy.',
        },
        written: {
          title: 'Pisemna ocena lekarza',
          price: '+29 €',
          description: 'Alergolog oceni i skomentuje wyniki Twojego badania oraz przepisze pisemne zalecenia.',
        },
        consultation: {
          title: 'Ocena lekarska + konsultacja',
          price: '+49 €',
          description: 'Alergolog oceni i skomentuje wyniki testów oraz przedstawi pisemne zalecenia.',
        },
      },
    },
    SCREENER: {
      allergens: '{allergensCount}',
      title: 'Podstawowy program testów alergicznych',
      titleResearch: 'products.SCREENER.titleResearch',
      titleProduct6month: 'products.SCREENER.titleProduct6month',
      orderTitle: 'Zamów badanie wstępne',
      description:
        'Test bada uczulenie {allergensCount} na alergeny, które powodują 95% szybkich reakcji alergicznych.',
      bullets: [
        'Badanie krwi na alergeny {allergensCount}',
        'Ocena alergologa i pisemne zalecenia',
        'Możliwość zadania dodatkowych pytań',
        '20-minutowa rozmowa wideo z alergologiem',
      ],
    },
    SCREENER_ASIT: {
      allergens: '{allergensCount}',
      title: 'AllergyChip',
      titleResearch: 'products.SCREENER_ASIT.titleResearch',
      titleProduct6month: 'products.SCREENER_ASIT.titleProduct6month',
      orderTitle: 'Zamów test AllergyChip',
      description:
        'Test bada uczulenie na alergeny {allergensCount}, które powodują 95% szybkich reakcji alergicznych.',
      bullets: [
        'Badanie krwi na alergeny {allergensCount}',
        'Ocena alergologa i pisemne zalecenia',
        'Możliwość zadania dodatkowych pytań',
      ],
      review: {
        auto: {
          title: 'Automatyczna interpretacja wyników',
          price: '+0 zł',
          description: 'Ocenimy, które objawy są prowokowane przez które zbadane alergeny',
        },
        written: {
          title: 'Szczegółowa interpretacja alergologa',
          price: '+80 zł',
          description:
            'Przedstawimy szczegółowy pisemny komentarz do wyników wykonanego testu oraz wstępne zalecenia lekarza alergologa',
        },
        consultation: {
          title: 'Szczegółowa interpretacja + konsultacja online',
          price: '+389 zł',
          description:
            'Przedstawimy szczegółową interpretację wyników oraz ich omówienie podczas 30-minutowej konsultacji online z lekarzem alergologiem.',
        },
      },
    },
    FREE_SCREENER_ASIT: {
      allergens: '66',
      title: 'Podstawowy program testów alergicznych',
      titleResearch: 'products.FREE_SCREENER_ASIT.titleResearch',
      titleProduct6month: 'products.FREE_SCREENER_ASIT.titleProduct6month',
      orderTitle: 'Zamów badanie wstępne',
      description:
        'Masz konkretne pytania dotyczące alergii i ich leczenia? Nasi lekarze pomogą Ci na nie odpowiedzieć.',
      bullets: ['Ocena alergologa i pisemne zalecenia', 'Możliwość zadania dodatkowych pytań'],
    },
    FREE_SCREENER: {
      allergens: '28',
      title: 'Podstawowy program testów alergicznych',
      titleResearch: 'products.FREE_SCREENER.titleResearch',
      titleProduct6month: 'products.FREE_SCREENER.titleProduct6month',
      orderTitle: 'Zamów badanie wstępne',
      description:
        'Masz konkretne pytania dotyczące alergii i ich leczenia? Nasi lekarze pomogą Ci na nie odpowiedzieć.',
      bullets: [
        'Ocena alergologa i pisemne zalecenia',
        'Możliwość zadania dodatkowych pytań',
        '20-minutowa rozmowa wideo z alergologiem',
      ],
    },
    IMMUNOTHERAPY_PLAN: {
      allergens: '295',
      title: 'Plan immunoterapii + konsultacja',
      titleResearch: 'Kompleksowe badania alergiczne w celu stworzenia planu immunoterapii',
      titleProduct6month: 'products.IMMUNOTHERAPY_PLAN.titleProduct6month',
      orderTitle: 'Zamów plan leczenia',
      description: '..',
      bullets: ['..'],
    },
    TREATMENT_PLAN: {
      allergens: '0',
      title: 'Immunoterapia przez 3 miesiące',
      titleResearch: 'products.TREATMENT_PLAN.titleResearch',
      titleProduct6month: 'Immunoterapia przez 6 miesięcy',
      orderTitle: 'Zarezerwuj zdalne leczenie immunoterapeutyczne',
      description: '..',
      bullets: ['..'],
    },
    FOOD_ID: {
      allergens: '{allergensCount}',
      title: 'Testy na nietolerancję pokarmową',
      titleResearch: 'products.FOOD_ID.titleResearch',
      titleProduct6month: 'products.FOOD_ID.titleProduct6month',
      orderTitle: 'Zarezerwuj test na nietolerancję pokarmową',
      description: 'Testy na uczulenie na {allergensCount} produkty spożywcze',
      bullets: ['Test na obecność {allergensCount} alergenów we krwi'],
      chat: {
        heading: 'Masz pytania dotyczące diety lub nietolerancji pokarmowych?',
      },
      legend: {
        description:
          'Test na nietolerancję pokarmową umożliwia określenie ilości przeciwciał IgG w krwi. Im wyższy poziom uwrażliwienia, tym większe prawdopodobieństwo, że dany pokarm może wywoływać objawy. Gdy test wykaże wysoką nadwrażliwość, a dany pokarm wywołuje objawy, wówczas u pacjenta diagnozowana jest nietolerancja pokarmowa.',
      },
      review: {
        auto: {
          title: 'Nie chcę konsultacji',
          price: '+0 zł',
          description: 'Otrzymasz standardową pisemną interpretację testu i zestaw zaleceń żywieniowych.',
        },
        written: {
          title: '',
          price: '',
          description: '',
        },
        consultation: {
          title: 'Dodaj konsultację',
          price: '+200 zł (Cena regularna 250 zł)',
          description:
            'Po otrzymaniu wyników badania dietetyk odpowie na Twoje pytania i pomoże stworzyć spersonalizowany plan zmian w Twojej diecie.',
        },
      },
    },
  },
  features: {
    ourClinic: 'Allergomedica',
    privateClinic: 'Prywatna klinika',
    visits: {
      title: 'Liczba odwiedzin',
      hint1: 'W celu oddania krwi w najbliższej klinice',
      hint2: 'Oddaj krew i zapoznaj się z wynikami',
    },
    timeToResult: {
      title: 'Czas oczekiwania na wyniki od pierwszej rejestracji',
      tier1: '7 dni',
      tier2: 'od 12',
    },
    fullResearch: {
      title: 'Molekularne badanie krwi',
    },
    screenerResearch: {
      title: 'Kompleksowe badanie krwi na alergię',
    },
    detailedProfile: {
      title: 'Szczegółowy profil Twojej alergii sporządza alergolog',
    },
    recommendations: {
      title: 'Szczegółowe pisemne zalecenia',
    },
    questions: {
      title: 'Możliwość zadawania kolejnych pytań później, w dogodnym dla Ciebie czasie',
    },
  },
  product: {
    title: 'Zamów szczegółowe badanie',
    text: 'Za pomocą naszego programu będziesz mógł szczegółowo ocenić, czy objawy, których doświadczasz, są spowodowane alergenami i jakie alergeny są przyczyną tych objawów. Przekażemy szczegółową analizę alergenów, a także zalecenia i metody leczenia.',
    recommended: 'Polecamy ',
    comingSoon: 'Wkrótce',
    select: 'Wybierz',
  },
  allergo_expert: {
    title: 'Kompleksowy program diagnostyczny alergii',
    bullets: [
      'Ocena i zalecenia alergologa',
      'Szczegółowy opis profilu alergenowego',
      'Zdalna konsultacja w celu odpowiedzi na pytania',
      'Badanie krwi na 295 alergenów (+ do 10 testów potwierdzających)',
      'Jeśli masz więcej pytań, będziesz miał okazję zadać je naszemu zespołowi',
    ],
  },
  short_test: {
    scale_text: ['Nie przejmuj się', 'Lekko uciążliwe', 'Umiarkowanie męczące', 'Bardzo męczące', 'Niezwykle męczące'],
  },
  shortTestAnswer: {
    title: 'Dziękujemy za wypełnienie ankiety.',
    description: {
      research:
        'Na podstawie Twoich odpowiedzi alergolog dobierze najdokładniejsze metody badań, a po otrzymaniu wyników badań przygotuje dla Ciebie zalecenia.',
      consultation: 'Twoje odpowiedzi pozwolą lekarzowi głębiej przyjrzeć się Twojemu przypadkowi przed konsultacją.',
    },
    weNeedYourAttention: 'Chcemy zwrócić Twoją uwagę',
    bookConsultation: 'Zarezerwuj termin konsultacji',
    benefitsTitle: 'Czego się dowiesz?',
    benefits: {
      SCREENER: [
        'Na które z {allergensCount} najpopularniejszych alergenów jesteś uczulony(a)?',
        'Które alergeny mogą wywołać wymienione przez Ciebie objawy, a które są nieistotne klinicznie',
        'Jak możesz poprawić jakość życia, dostosowując swój styl życia',
        'Jakie masz możliwości leczenia?',
      ],
      FULL: [
        'Na który z 295 najpopularniejszych alergenów i ich komponentów jesteś uczulony?',
        'Które alergeny mogą wywołać wymienione przez Ciebie objawy, a które są nieistotne klinicznie',
        'Jak możesz poprawić jakość życia, dostosowując swój styl życia',
        'Jakie masz możliwości leczenia i czy immunoterapia jest dla Ciebie odpowiednia?',
      ],
    },
    viewSample: {
      title: 'Zobacz jak wygląda przykładowy wynik testu',
      button: 'recenzja',
    },
    comparison: {
      title: 'Porównaj Allergomedica z innymi klinikami',
      included: 'Dołączony',
      notIncluded: 'Nie wliczone',
    },
    defaultProductTitle: 'Kompleksowy program diagnostyczny alergii',
  },
  allergyEvaluation: {
    subscribeAnswer: {
      title: 'Wprowadź swój adres email',
      description: 'Aby ułatwić dostęp do wyników, wyślemy Ci link na podany przez Ciebie adres e-mail.',
      buttonText: 'Wyślij',
    },
  },
  shortAnswer: {
    hello: 'Cześć,',
    title: 'Przygotowaliśmy dla Ciebie analizę alergii',
    description:
      'Analiza opiera się na statystykach 2879 osób, które skorzystały już z usług Allergomedica. Na podstawie tych danych oceniliśmy podane przez Ciebie objawy i przeprowadziliśmy diagnostykę pod kątem występowania alergii.',
    possibility: {
      title: 'Prawdopodobieństwo ostrego rodzaju alergii',
      items: {
        veryLow: 'Bardzo niski',
        low: 'Niski',
        medium: 'Przeciętny',
        high: 'Wysoki',
        veryHigh: 'Bardzo wysoki',
      },
      hint: 'Badanie może pomóc w podjęciu decyzji, czy warto poddać się testom na alergie, ale nie powinno być wykorzystywane jako diagnoza medyczna.',
    },
    allergens: {
      title: 'Najczęstsze alergeny',
      text: 'To są alergeny, które najczęściej powodują opisane przez Ciebie objawy.',
      textNoAllergens: 'Wymienione objawy nie są zwykle wywoływane przez gwałtowne reakcje alergiczne.',
    },
    moreBlock: {
      title: 'Co dalej?',
      items: {
        low: 'Szanse na to, że objawy zostały wywołane bezpośrednią reakcją alergiczną są niskie. Zalecamy skonsultowanie się z lekarzem w celu oceny pochodzenia objawów.',
        high: 'Istnieje prawdopodobieństwo, że objawy, o których wspomniałeś, są spowodowane szybką reakcją alergiczną. Aby się upewnić, zalecamy wykonanie wstępnego testu alergicznego.',
        veryHigh:
          'Jest wysoce prawdopodobne, że objawy, których doświadczasz, są wywołane szybką reakcją alergiczną. Jednak tylko test alergiczny może ją dokładnie zdiagnozować. Zalecamy wykonanie kompleksowego lub wstępnego testu alergicznego',
      },
      hint: 'Wyniki badania mogą pomóc w podjęciu decyzji, czy warto poddać się testom na alergie, ale nie powinny być wykorzystywane jako diagnoza medyczna.',
    },
    symptoms: {
      title: 'Objawy, o których wspomniałeś',
      noSymptomsIndicated: 'Nie wskazano żadnych objawów',
      noDetails: 'Nie nazwany',
      items: {
        often: 'Częsty',
        common: 'Zwykły',
        rare: 'Rzadki',
      },
      hint: 'Alergie mogą manifestować się różnymi objawami. Niektóre objawy są specyficzne i występują głównie w alergiach, podczas gdy inne są bardziej powszechne w innych chorobach. Specyfika objawów pomaga określić ryzyko wystąpienia alergii.',
    },
    periodicity: {
      title: 'Okresowo',
      hint: 'Powtarzające się objawy są częstą oznaką alergii. Jeśli zauważysz okresowe występowanie objawów, prawdopodobieństwo wystąpienia alergii jest wyższe.',
    },
    circumstances: {
      title: 'Okoliczności',
      hint: 'Reakcje typu gwałtownego występują natychmiast po ekspozycji na alergen, więc jeśli zauważysz okoliczności prowadzące do objawów, zwiększa to prawdopodobieństwo alergii.',
    },
    duration: {
      title: 'Czas trwania objawów',
      items: {
        someMonth: 'Parę miesięcy',
        justNow: 'Teraz ',
        fiveYear: '5+ lat',
      },
      hint: 'Jeśli objawy pojawiają się i powtarzają przez dłuższy czas, prawdopodobieństwo, że objawy te są spowodowane alergią, jest większe.',
    },
  },
  test_answer: {
    navigation: {
      allergyProfile: 'Profil alergiczny',
      recommendations: 'Zalecenia',
      allergens: 'Wszystkie alergeny',
    },
    hello: 'Cześć,',
    hero_subtitle: 'Przeszedłeś kompleksowy program badań pod kątem alergii.',
    hero_text: 'Wskazałeś w ankiecie, że odczuwasz {liczba} {zmęczenie} i możliwą alergię {związane Objawy}.',
    yourAnswerIsNotReady: 'Cieszymy się, że jesteś zainteresowana/y, ale Twoje wyniki nie są jeszcze gotowe.',
    youAreSensitive: 'Jesteś uczulony',
    noSensitivity: 'Nie jesteś uczulony',
    allergenGroupPlural: 'grupa alergenów|grupy alergenów|grup alergenów',
    origin_of_symptoms: 'Pochodzenie objawów',
    here_we_review_diseases_and_symptoms:
      'Tutaj przyjrzymy się, jakie choroby i objawy mogą powodować reakcje alergiczne.',
    typical_symptoms: 'Typowe objawy',
    allergens: 'Alergeny',
    possibly_provoking_symptoms: 'Możliwe objawy prowokujące',
    interpretation_of_sensitivity_intensity: 'Interpretacja mocy uczulenia',
    no_symptoms: 'Powiązane objawy nie są wymienione',
    unclear_origin: 'Nieokreślone pochodzenie',
    doctorPosition: {
      LINAS: 'Alergolog i immunolog kliniczny',
      ANA: 'Lekarz alergolog',
      EGLE: 'Lekarz alergolog',
      AGNE: 'Lekarz medycyny, starszy rezydent alergologii i immunologii klinicznej',
      KESTUTIS: 'Lekarz alergolog',
      LAIMA: 'Alergolog i immunolog kliniczny',
      GABIJA: 'Doktor nauk medycznych',
    },
    detailedAllergenProfile: {
      title: 'Szczegółowy profil alergenów',
      description:
        'Tutaj szczegółowo omówimy, jakie możliwe alergie odkryliśmy w badaniu krwi i wyjaśnimy ich powiązania i znaczenie.',
    },
    unidentifiedSymptoms: {
      title: 'Objawy niewiadomego pochodzenia',
    },
    primaryAllergen: {
      title: 'Główny alergen',
      tooltip:
        'Alergen uważany za reprezentatywny dla swojej grupy (np. pyłki drzew) i zwykle wywołujący reakcje alergiczne. Zwykle najpierw rozwija się alergia na ten alergen.',
    },
    noPrimaryAllergen: 'Brak głównego alergenu',
    components: {
      title: 'komponenty',
      onlyInFullResult:
        'Poszczególne komponenty alergenów są identyfikowane tylko poprzez szczegółowe molekularne testy alergiczne',
      tooltip:
        'W rzeczywistości nie cały alergen uczula daną osobę, ale jego składniki - komponenty. Pojedynczy alergen ma zwykle kilka różnych komponentów, ale nie wszystkie z nich mają właściwości alergizujące. Osoba może być uczulona na jeden lub kilka różnych komponentów tego alergenu. Najczęstszymi komponentami są białka. Komponenty są oznaczane symbolami składającymi się z łacińskiego skrótu nazwy alergenu i numeru. Na przykład: pierwszy komponent pyłku brzozy (Betula verrucosa) jest oznaczony jako Bet v 1. Komponenty pomagają zidentyfikować główne alergeny, przewidzieć ryzyko wystąpienia określonych reakcji alergicznych i pomóc w wyborze konkretnych metod leczenia.',
    },
    secondaryAllergens: {
      title: 'Inne powiązane alergeny',
      onlyInFullResult:
        'Możemy zidentyfikować powiązane alergeny i reakcje krzyżowe tylko za pomocą kompleksowych molekularnych testów alergicznych',
      tooltip:
        'Alergeny, które mają strukturalne podobieństwa do głównego alergenu w grupie i dlatego mogą również powodować objawy alergiczne. W większości przypadków uczulenie na te alergeny występuje później niż na alergen główny.',
    },
    relatedSymptoms: {
      title: 'Możliwe objawy spowodowane tymi alergenami',
      tooltip:
        'Tutaj wymieniliśmy wszystkie możliwe objawy, które mogą być spowodowane przez te alergeny. W ankiecie zaznaczyłeś wyróżnione objawy jako uciążliwe',
    },
    activePeriod: {
      title: 'Obecny okres',
      tooltip: 'Wymienione powyżej alergeny pojawiają się zazwyczaj w podanym poniżej okresie.',
      months: 'Miesiące zaznaczone pogrubioną czcionką są istotne',
    },
    viewAllAllergens: 'Wyświetl wszystkie przetestowane alergeny ({num}).',
    viewRecommendations: 'Zobacz rekomendacje',
    symptoms: 'Objawy',
    tiringPlural: 'frustrująca|frustrujące|frustrujące',
    relatedSymptomsPlural: 'powiązany objaw|powiązane objawy|powiązanych objawów',
    provokePlural: 'sprowokował|sprowokowały|sprowokowały',
    symptomsPlural: 'objaw|objawy|objawów',
    no_allergens: 'Nie stwierdzono żadnych alergenów prowokujących',
    sensitivity: [
      'Brak uczulenia',
      'Niewielkie uczulenie',
      'Umiarkowane uczulenie',
      'Wysoka wrażliwość',
      'Wysoka wrażliwość',
    ],
    sensitivityShort: ['Nie ma', 'Łatwy', 'Przeciętny', 'Wysoki', 'Wysoki'],
    sensitivitySuffix: ', które może wywoływać ',
    manySymptoms: 'wiele objawów',
    noSymptoms: ', które nie powinno wywoływać objawów',
    noSensitivityAllergensListTitle: 'Zostałeś przebadany pod kątem możliwego uczulenia na {num} alergenów',
  },
  recommendations: {
    title: 'Zalecenia',
    titleActions: 'Zacznij od tych zaleceń',
    titleAll: 'Wszystkie zalecenia',
    otherRecommendations: 'Inne zalecenia',
    description:
      'Na początek wybraliśmy dla Ciebie kilka zaleceń dotyczących stylu życia i leczenia, które mogą poprawić jakość Twojego życia. Wypróbuj je i zaobserwuj, czy poczujesz poprawę. Na podstawie Twojej opinii zaktualizujemy rekomendacje, aby dostosować je do Twoich potrzeb. Należy pamiętać, że zastosowanie niektórych zaleceń wymaga konsultacji z alergologiem.',
    testedGood: 'Włącz to zalecenie do swojego stylu życia',
    testedBad: 'Będziemy szukać innych sposobów pomocy',
    testedTime: 'Testowano w dniu {data}',
    button: {
      work: 'Działa',
      notWork: 'Nie działa',
      testedWork: 'Przetestowany',
      testedNotWork: 'Przetestowany',
    },
  },
  textPage: {
    remoteConsultation: {
      title: 'Zasady konsultacji zdalnych',
    },
    privacyPolicy: {
      title: 'Polityka prywatności',
    },
    termsAndConditions: {
      title: 'Warunki usługi',
    },
    signAgreement: {
      title: 'Podpisanie zgody',
    },
  },
  bloodRegistration: {
    title: 'Wybierz klinikę i termin pobrania krwi',
    selectClinicStep: 'Wybierz klinikę',
    pickerPlaceholder: 'Wybierz...',
    completedTitle: 'Świetnie!',
    completedLocation: 'Wybrałeś {tytuł} ({adres}).',
    completedFurtherAction: 'Zadzwoń do kliniki telefonicznie:',
    completeFurtherActionSuffix: 'i umów się na wizytę.',
    pickUpBoxPlaceholder: 'Wpisz adres lub numer automatu',
  },
  dashboard: {
    orderNewItem: 'Zamów nowy program',
    title: 'Co dalej?',
    noOrders: 'Wygląda na to, że nie masz żadnych zamówień',
    descriptionNoBloodRegistration: 'Następnie należy zarejestrować się w wybranej klinice i oddać krew.',
    descriptionNoBlood:
      'Wszystko, co musisz zrobić, to oddać krew, a następnie możemy przeprowadzić test i dokonać oceny wyników.',
    tasks: {
      orderCompletedHeading: 'Twój wynik jest gotowy',
      orderCompletedDescription:
        'Nasi specjaliści przeprowadzili badanie krwi i ocenili wyniki. Możesz teraz wyświetlić wyniki testu',
      orderCompletedDescriptionConsultation: 'Nasi specjaliści przygotowali dla Ciebie rekomendacje.',
      incompleteHeading: 'Wykonaj te zadania',
      completedHeading: 'Wykonałeś wszystkie zadania!',
      description:
        'Gdy nasi eksperci przeprowadzą badanie, poinformujemy Cię o tym e-mailem {email}. Wyniki testu znajdziesz w sekcji "Wyniki" powyżej.',
      giftCouponReady: 'Twój kupon upominkowy jest gotowy',
    },
    bloodRegistration: {
      incompleteTitle: 'Zarejestruj się na pobranie krwi',
      incompleteDescription:
        'Podczas badania krwi zostanie wykonane kompleksowa analiza 295 alergenów oraz aż 10 badań korygujących ',
      completed: 'Rejestracja na pobranie krwi przebiegła pomyślnie',
      callClinicByPhone: 'Zadzwoń do kliniki pod numer telefonu {telefon} i umów się na dogodny dla Ciebie termin.',
      completedDescription:
        'Pobranie krwi nie będzie Cię dodatkowo kosztować. W klinice zaznacz, że przyszedłeś na pobranie krwi programu alergicznego Allergomedica i pokaż e-mail potwierdzający rejestrację.',
      selectedClinic: 'Wybrana przez Ciebie klinika:',
      selectedTime: 'Twój czas rejestracji:',
      button: 'Rejestracja',
    },
    research: {
      completed: 'Ankieta została pomyślnie wypełniona',
      description: 'Oddaj krew, jeśli jeszcze tego nie zrobiłeś, a my poinformujemy Cię, gdy wyniki będą gotowe',
    },
    orderedAt: 'Zamówiono:',
    orderId: 'Numer zamówienia:',
    status: {
      ongoing: 'W trakcie',
      completed: 'Zakończony',
    },
    order: 'Zamówienie',
    resultPreview: 'Zobacz wyniki',
  },
  form: {
    password: 'Hasło',
    email: 'Adres e-mail',
    surname: 'Nazwisko',
    name: 'Imię',
    phone: 'Telefon',
    phoneNo: 'Numer telefonu',
    birthDate: 'Data urodzenia (będzie ona konieczna podczas pobrania krwi w klinice)',
    placeholderBirthDate: 'Podaj dokładną datę urodzenia pacjenta',
    birthDateFormat: 'Wpisz datę oddzieloną myślnikami, np.: 1999-12-08',
    gender: 'Płeć',
    genderSelect: 'Wybierz płeć',
    genderMale: 'Mężczyzna',
    genderFemale: 'Kobieta',
    genderPickerPlaceholder: 'Wybierz płeć',
    personCode: 'Osobisty kod',
    newPassword: 'Nowe hasło',
    repeatPassword: 'Powtórz hasło',
    YourPersonalIdNo: 'Twój osobisty kod',
    YourChildCode: 'Osobisty kod Twojego dziecka',
    spouseName: 'Imię małżonka',
    spouseSurname: 'Nazwisko współmałżonka',
    spouseEmail: 'Adres e-mail współmałżonka',
    address: 'Adres',
    city: 'Miasto',
    zipCode: 'Kod pocztowy',
    atLeastEightSymbols: 'Minimum 8 znaków',
    agreementForScienceResearch:
      'Wyrażam zgodę na wykorzystanie moich danych personalnych oraz próbki krwi do celów naukowych',
    alertSuccessAnswer: {
      title: 'Rejestracja przebiegła pomyślnie',
      text: 'Dziękuję! Dla Twojej wygody link do wyników znajdziesz w liscie wysłanym na podany przez ciebie adres e-mail.',
    },
    alertDangerAnswer: {
      title: 'Rejestracja nie powiodła się',
      text: 'Wpisałeś nieprawidłowy adres e-mail.',
    },
    requiredFields: 'Pola oznaczone * są obowiązkowe.',
  },
  diForm: {
    title: 'Wprowadź kod uczestnika',
  },
  checkout: {
    success_title: 'Zamówienie powiodło się',
    success_text: 'Otrzymałeś list potwierdzający płatność wysłany na podany adres e-mail',
    sign_success_title: 'Dokument został podpisany pomyślnie',
    sign_success_text: 'Na podany adres e-mail otrzymałeś list potwierdzający złożenie podpisu',
    titleContactInfo: 'Informacje kontaktowe',
    titleSpouseContactInfo: 'Dane kontaktowe współmałżonka',
    titleChooseBank: 'Wybierz metodę płatności',
    titleInfoForRemoteConsultation: 'Informacje dotyczące konsultacji zdalnych',
    explanationTextInfoForRemoteConsultation: 'Kod imienny jest wymagany przy przepisywaniu leków na receptę.',
    explanationTextInfoForChildsResearch:
      'Dzieci do lat 12 wymagają zgody obojga rodziców. Na podany adres e-mail prześlemy link do elektronicznego podpisu dokumentu.',
    failed: {
      title: 'Zamówienie nie powiodło się',
      description: 'Nie udało się zrealizować Twojej płatności. Spróbuj ponownie lub skontaktuj się z nami',
    },
    loginTitle: 'Zaloguj się',
    loginDescription:
      'Jeśli zarejestrowałeś się już u nas w przeszłości, zaloguj się na swoje konto, a będziesz mógł śledzić status swojego Programu i podejmować kolejne kroki.',
    titleYourOrder: 'Twoje zamówienie',
    titleBilling: 'Dane do faktury',
    hide: 'Ukryj',
    iHaveDiscount: 'Mam kod rabatowy',
    discount: 'Rabat',
    subTotal: 'Suma częściowa',
    discountCode: 'Kod rabatowy',
    amount: 'Suma do zapłaty',
    checkboxIHaveReadAnd: 'Przeczytałem i akceptuję',
    checkboxPrivacyPolicy: 'Polityka prywatności',
    checkboxAnd: 'I',
    checkBoxTermsOfService: 'Zasady świadczenia usług',
    checkBoxAgreeAll: 'Akceptacja wszystkich opcji',
    checkboxIAgreeThatImunodiagnostika:
      'Wyrażam zgodę na przetwarzanie moich danych osobowych na potrzeby utworzenia konta, przeprowadzania testów oraz dostarczania analiz i zaleceń zdrowotnych przez Immunodiagnostics, UAB.',
    checkboxIAgreeToGetDoctorAdvice:
      'Wyrażam zgodę na otrzymywanie wskazówek lekarza dotyczących życia z alergiami, wiadomości zdrowotnych i ofert specjalnych. Zawsze możesz łatwo zrezygnować z subskrypcji',
    checkboxPayseraText:
      'Informujemy, że informacje o rachunku i usługi uruchamiania płatności będą świadczone przez Przelewy24 zgodnie z następującymi warunkami świadczenia usług',
    checkboxPayseraTerms: 'zasady',
    checkboxPayseraAgreement:
      '. Kontynuując płatność, potwierdzasz, że zgadzasz się na świadczenie tych usług i warunki ich świadczenia.',
    checkboxAgreeRemoteConsultation: 'Przeczytałem i zgadzam się z ',
    checkboxAgreeRemoteConsultationSuffix: 'zasady konsultacji zdalnych.',
    checkboxAgreeChildResponsibility:
      'Oświadczam, że jestem osobą upoważnioną do podejmowania decyzji zdrowotnych w imieniu dziecka.',
    previewProfile: 'Przejdź so Twojego Profilu',
    titleResearch: 'Podpisanie zgody',
    SigningOfDocumentsDokobit: 'Podpisywanie dokumentów odbywać się będzie poprzez system Dokobit.',
    showOrder: '',
    fillQuestionnaire: 'Wypełnij kwestionariusz',
    dataIsSecured: '&#x1F512; Państwa dane są bezpieczne i chronione.',
  },
  registration: {
    title: 'Zaloguj się lub utwórz konto',
    text: 'W jednym miejscu mogą Państwo monitorować postępy zamówienia, uzyskać dostęp do wyników i zarządzać swoimi danymi.',
    haveAccount: 'Posiadasz już konto?',
    login: 'Zaloguj sie',
  },
  login: {
    title: 'Zaloguj sie',
    forgotYourPasswordLink: 'Zapomniałeś hasła?',
    dontHaveAccount: 'Nie masz konta?',
    register: 'Zarejestruj się',
  },
  forgotPassword: {
    requestPasswordReset: 'Przypomnienie hasła',
    successTitle: 'Link do przypomnienia hasła został wysłany na podany adres e-mail',
    finishPasswordReset: 'Zmień swoje hasło',
    changePasswordSuccessTitle: 'Hasło zaktualizowane',
    linkToLogin: 'Wróć do logowania',
  },
  selectPatient: {
    title: 'Wybierz, kto będzie testowany:',
    label: 'Wybierz z poniższej listy lub dodaj nowy.',
    labelRadio: 'Wybierz pacjenta lub utwórz nowego',
    addNewPatient: 'Dodaj nowego pacjenta',
    button: 'Kontynuuj',
  },
  createPatient: {
    title: 'Dodatkowe informacje o pacjencie',
    label: 'Badanie przeprowadza się:',
    addNewPatient: 'Dodaj nowego pacjenta',
    button: 'Kontynuować',
    agreementForSienceResearch: 'Kontynuować',
  },
  createPatientChild: {
    title: 'Dodatkowe informacje o Twoim dziecku',
    label: 'Badanie jest w toku:',
    addNewPatient: 'Dodaj nowego pacjenta',
    button: 'Kontynuuj',
  },
  navigation: {
    orders: 'Status zamówienia',
    result: 'Twoje wyniki ',
    consultations: 'Informacje i pytania',
    yourProfile: 'Mój profil',
    signOut: 'Rozłącz się',
    selectItem: 'Wybierz stronę',
    comingSoon: 'Wkrótce',
    openMainMenu: 'Otwórz menu główne',
  },
  profileAllergens: {
    weHaveEvaluated: 'Wynik ',
    allergensTotal: ' alergenów',
    footnotes: {
      prefix: 'Źródło danych dotyczących badań krwi znajdziesz tutaj',
      link: 'Tutaj',
      suffix: '(wyniki zobaczysz po wpisaniu daty urodzenia pacjenta)',
    },
    table: {
      allergen: 'Alergeny',
      component: 'Komponent',
      biochemicalDesignation: 'Grupa białek',
      value: 'Wynik',
      strength: 'Wytrzymałość',
    },
    title: {
      researchOverview: 'Podsumowanie wyników',
      researchResults: 'Wyniki',
    },
    tableOverview: {
      group: 'Grupy',
      amount: 'Ilość analitów',
    },
  },
  legend: {
    title: 'Interpretacja wyniku',
    description:
      'Siła uczulenia nie wskazuje bezpośrednio, czy reagujący alergen rzeczywiście wywoła objawy. Jednak im silniejsze uczulenie, tym bardziej prawdopodobne jest, że wynik jest istotny klinicznie.',
    senseHigh: 'Wysokie uczulenie (>3,5 kU/l)',
    senseMedium: 'Umiarkowane uczulenie (0,7 - 3,49 kU/l)',
    senseLow: 'Łagodne uczulenie (0,35 - 0,69 kU/l)',
    senseNone: 'Brak uczulenia (0 - 0,34 kU/l)',
    noNum: {
      senseHigh: 'Wysoki stopień nadwrażliwości',
      senseMedium: 'Umiarkowany stopień nadwrażliwości',
      senseLow: 'Łagodny stopień nadwrażliwości',
      senseNone: 'Brak nadwrażliwości',
    },
  },
  order: {
    steps: ['Wypełnij ankietę', 'Zapłać', 'Oddaj krew', 'Uzyskaj wynik'],
    stepsConsultation: [
      'Wypełnij ankietę',
      'Zapłać',
      'Umów swoją konsultację online',
      'Omów swój przypadek z lekarzem specjalistą',
    ],
    stepsResearch: ['Wypełnij ankietę', 'Podpisz zgodę', 'Oddaj krew', 'Uzyskaj wyniki'],
    stepsImmunotherapy: ['Wypełnij ankietę', 'Zapłać ', 'Oddaj krew', 'Opracujemy plan leczenia'],
    stepsQuestionnaireAfterSale: ['Zapłać', 'Wypełnij ankietę', 'Oddaj krew', 'Odbierz wynik badania'],
  },
  chat: {
    inputPlaceholder: 'Twoja wiadomość...',
    heading: 'Masz pytania dotyczące alergii?',
    headingHasDr: 'Pytania?',
    description: 'Zadaj je naszym specjalistom',
    descriptionHasDrPlural: 'Twój lekarz|Twoi lekarze',
    replyTimeDescription: 'Zwykle odpowiadamy w ciągu kilku godzin',
    doctorsTitle: 'Nasz zespół lekarzy',
    consultation: {
      title: 'Zdalna konsultacja',
      description: 'Chcesz omówić kwestie podczas zdalnej konsultacji? Pierwsza konsultacja jest bezpłatna.',
      button: 'Rejestracja',
    },
  },
  questionnaireConsultationIntro:
    'Gwarantujemy, że konsultację otrzymasz w ciągu 2 dni roboczych od momentu opłacenia zamówienia',
  questionnaireGenericIntro:
    'Wypełnij ankietę, a my doradzimy Ci, który program badań alergicznych Allergomedica będzie dla Ciebie najlepszy',
  questionnaireCheckAllergyIntro:
    'Podejrzewasz, że masz alergię? Kwestionariusz ten pomoże Ci ocenić prawdopodobieństwo, że Twoje objawy są spowodowane natychmiastową reakcją alergiczną.',
  questionnaireDuration: 'Ankieta zajmie do 5 minut.',
  doctors: [
    {
      name: 'Linas Griguola',
      nameAccusative: 'Linę Griguolos',
      role: 'Alergolog i immunolog kliniczny',
      subrole: 'Prezes Zarządu Allergomedica ',
      imageUrl: 'LINAS',
      infoLink: 'linas-griguola',
    },
    {
      name: 'Anna Kozłowska',
      nameAccusative: 'Anna Kozłowska',
      role: 'Alergolog i immunolog kliniczny; alergolog dziecięcy',
      subrole: 'Lekarz Allergomedica',
      imageUrl: 'ANA',
      infoLink: 'Ana-Kozlova',
    },
    {
      name: 'Eglė Žilėnaitė',
      role: 'Alergolog i immunolog kliniczny',
      subrole: 'Lekarz Allergomedica',
      imageUrl: 'EGLE',
      infoLink: 'egle-zilenaite',
    },
    {
      name: 'Kęstutisa Černiauskasa',
      role: 'Alergolog i immunolog kliniczny',
      subrole: 'Lekarz Allergomedica',
      imageUrl: 'KESTUTIS',
      infoLink: 'kestutis-cerniauskas',
    },
    {
      name: '',
      role: '',
      subrole: '',
      imageUrl: '',
      infoLink: '',
    },
  ],
  reviewsBanner: {
    title: 'Jak oceniasz usługi {companyName}?',
    text: 'Bylibyśmy bardzo wdzięczni, gdybyś podzielił się swoimi przemyśleniami. Zajmie Ci to 1-2 minuty.',
    button: 'Więcej',
    dismiss: 'Zamknij',
  },
  consultationBanner: {
    title: 'Chcesz znaleźć sposób na zmniejszenie lub wyleczenie objawów? A może po prostu skonsultuj się?',
    text: 'Aby uzyskać pełne badanie, dodaj zdalną konsultację lekarską. Podczas niego będzie można podyskutować\nwyniki badań i stworzyć plan leczenia. Będziesz mógł to zrobić podczas procesu składania zamówienia.',
  },
  feedback: {
    success_title: 'Dziękujemy za pomoc w doskonaleniu się!',
    success_text: 'Twoja odpowiedź została pomyślnie wysłana.',
  },
  consultationSuccess: {
    success_title: 'Dziękuję!',
    success_text: 'Twoja rejestracja przebiegła pomyślnie',
  },
  calendar: {
    title: 'Czas wolny lekarza',
    freeTime: 'terminy',
    titleFreeTime: 'Czas wolny - ',
    previousMonth: 'W zeszłym miesiącu',
    nextMonth: 'W następnym miesiącu',
    noAppointments: 'Nie ma wolnych terminów',
    schedule: 'Harmonogram dla',
    showDates: 'Pokaż daty',
    weekDays: {
      M: 'Pan',
      Tue: 'A',
      W: 'T',
      T: 'K',
      F: 'Pan',
      S: 'S',
      Su: 'S',
    },
  },
  immunotherapy: {
    titleTreatment: 'Zgoda na rozpoczęcie leczenia',
    titleAddress: 'Adres dostawy leków',
    subTitleAddress: 'Pod jaki adres chcesz otrzymać leki?',
    form: {
      terms: {
        firstRow: 'Zgadzam się na leczenie ASIT.',
        firstRowChild: 'Wyrażam zgodę na leczenie mojego dziecka metodą ASIT.',
        secondRow: 'zapoznałem się z ',
        secondRowLinkText: 'protokół leczenia.',
        items: [
          'Rozumiem cel zamierzonego leczenia, czas jego trwania, skuteczność i możliwość wystąpienia niepożądanych reakcji;',
          'Poinformowałem o swoich chorobach i przyjmowanych lekach;',
          'Zadałem niepokojące mnie pytania dotyczące tej metody leczenia i otrzymałem odpowiedzi;',
        ],
        itemsChild: [
          'Rozumiem cel zamierzonego leczenia, czas jego trwania, skuteczność i możliwość wystąpienia niepożądanych reakcji;',
          'Informowałam o chorobach dziecka i przyjmowanych lekach;',
          'Zadałem niepokojące mnie pytania dotyczące tej metody leczenia i otrzymałem odpowiedzi;',
        ],
      },
    },
    successText: 'Dane ankiety zostały zapisane.',
    treatmentPlan: {
      title: 'Plan leczenia',
      titleDuration: 'Pozostały czas na zapoznanie się z planem leczenia',
      subtitleAllergenPlural: 'Alergen|Alergeny',
      subtitleSymptoms: 'Objawy',
      subtitleDrugsForSymptoms: 'Leki łagodzące objawy',
    },
    treatmentProgress: {
      title: 'Postęp leczenia',
      titleSymptoms: 'Nasilenie objawów',
      titleProgressBlock: 'Ogólny postęp',
      titleChart: 'Zmiana nasilenia objawów w czasie',
      chartText: 'Postępy leczenia w %',
    },
    treatmentDayPlural: 'dzień|dni|dni',
    tabs: {
      ask: 'Zapytaj',
      progress: 'Postęp',
      plan: 'Plan',
    },
  },
  anamnesis: {
    questionnaire: {
      description:
        'Przed wizytą wypełnij ankietę dotyczącą występujących u Ciebie objawów. Dzięki temu będziemy mogli głębiej poznać Twoją sytuację i lepiej wykorzystać czas przeznaczony na konsultację.',
      submit: {
        title: 'Wyślij dane aplikacji',
        privacypolicy1: 'Klikając wyślij wyrażasz zgodę na udostępnienie danych',
        privacypolicy1IsSecond: 'Klikając Zakończ wyrażasz zgodę na udostępnienie danych',
        privacypolicy2: 'i zgadzasz się na',
        privacypolicyLinkTex: 'politykę prywatności programu',
        termsAndConditions1: 'I ',
        termsAndConditionsLinkText: 'zasady użytkowania.',
        isSecond: {
          title: 'Dziękujemy, w tym przypadku nie ma potrzeby wypełniania ankiety',
          title2: 'Twój lekarz zna już Twoją sytuację.',
        },
      },
      successTitle: 'Twoja ankieta została pomyślnie wysłana do lekarza',
      error: 'Nie udało się wysłać',
      upload: {
        title: 'Prześlij dodatkowe informacje',
        description: 'Prześlij dodatkowe informacje, które pomogą lekarzowi szybciej ocenić Twoją sytuację:',
        list: [' Fotografie zaobserwowanych objawów skórnych.', 'Wyniki testów alergicznych'],
        success: 'Twoje dane zostały pomyślnie przesłane do lekarza',
        noPatientTitle: 'Zły link.',
        noPatientText: 'Skontaktuj się ze swoim lekarzem konsultującym.',
      },
    },
  },
  selectConsultationReview: {
    title: 'Wybierz sposób, w jaki chcesz ocenić wyniki badania:',
  },
  treatmentProtocol: {
    title: 'Protokół leczenia',
  },
  locations: {
    workingHours: 'Godziny otwarcia:',
  },
  meta: {
    description:
      'Najnowocześniejsza diagnostyka i leczenie chorób alergicznych. Testy alergiczne i testy na alergeny w zaciszu własnego domu. Allergomedica - dla życia bez alergii.',
    title: 'AllergoMedica - Nowoczesny sposób na zrozumienie alergii.',
    url: 'https://app.allergomedica.pl',
  },
  footer: {
    companyInfo: 'UAB Imunodiagnostika, nr rejestracyjny firmy: 303064228',
    address: 'ul. Moletu 16, Didziosios Rieses, LT-14260 Wilno',
    phone: '+370 640 65680',
    email: 'info@allergomedica.pl',
  },
  FOOD_ID: {
    test_answer: {
      navigation: {
        allergyProfile: 'Podsumowanie',
        recommendations: 'Zalecenia',
        allergens: 'Wyniki',
      },
      hello: 'Witaj,',
      hero_subtitle: 'Posumowanie wyników',
      hero_text: 'Zaznaczyłeś {num} Masz {tiring} i możliwe objawy {relatedSymptoms}',
      summary: {
        weight: 'Waga',
        kg: 'kg',
        height: 'Wzrost',
        bmi: 'Wskaźnik masy ciała (BMI)',
        BMI: 'BMI',
        cm: 'cm',
      },
      youAreSensitive: 'Zdiagnozowano u Ciebie nadwrażliwość pokarmową na',
      noSensitivity: 'Nie jesteś uczulony na testowane pokarmy.',
      allergenGroupPlural: 'produkt spożywczy|produkty spożywcze|produktów spożywczych',
      yourSymptoms: 'Objawy',
      profileAllergens: {
        weHaveEvaluated: 'Wyniki szczegółowe dla',
        allergensTotal: 'produktów spożywczych',
        table: {
          allergen: 'Produkty spożywcze',
          strength: 'Klasa/stopień nadwrażliwości',
        },
      },
      habits: {
        youAreSensitivePlural: 'Określone przez Ciebie|Określone przez Ciebie|Określone przez Ciebie',
        noHabits: 'Nie stwierdzono u Ciebie nawyków żywieniowych i stylu życia, które mogą wywoływać objawy.',
        habitsPlural:
          'nawyki związane z dietą i stylem życia|nawyki związane z dietą i stylem życia|nawyki związane z dietą i stylem życia.',
        canProvokeSymptoms: ', które mogą wywoływać objawy.',
        canProvokeYou: 'Może wywoływać objawy',
        symptoms: 'objawy',
        youAreSensitiveStaticTitle:
          'Określone przez Ciebie nawyki związane z dietą i stylem życia, które mogą wywoływać obiawy',
        canProvokeYouPlural: 'Mogą wywoływać objawy',
      },
      symptoms: {
        title: 'Przeanalizujmy wymienione przez Ciebie objawy',
        titleHabitsThatCanProvokeSymptom: 'Nawyki, które mogą wywoływać ten objaw:',
        titleFoodThatCanProvokeSymptom: 'Pokarmy, które mogą wywołać ten objaw:',
        titleOtherFactors: 'Inne czynniki:',
        factoriesWhich: 'Czynniki, które',
      },
      emptyState:
        'Aby otrzymać pełną interpretację wyników Twojego testu oraz zestaw zaleceń żywieniowych, prosimy wypełnij poniższą ankietę.  Laboratoryjne wyniki Twojego testu będą widoczne w sekcji "Wyniki".',
      emptyStateRecommendationsTitle: 'Kwestionariusz nie został jeszcze wypełniony',
      emptyStateRecommendations:
        'Pamiętaj aby wypełnić kwestionariusz dotyczący objawów i nawyków żywieniowych, abyśmy mogli przygotować dla Ciebie spersonalizowane zalecenia dietetyczne.',
    },
    recommendations: {
      title: 'Zalecenia',
      description:
        'Specjalnie dobrane dla Ciebie zmiany w diecie i stylu życia, które mogą przyczynić się do poprawy Twojego samopoczucia.',
      titleProducts: 'Zalecane przez nas produkty, które należy ograniczyć lub zastąpić',
      titleProductSymptoms: 'Powiązane objawy:',
      titleHowToAvoid: 'Jak ich unikać?',
      titleHowToFollowRecommendations: 'Jak postępować zgodnie z zaleceniami?',
      textHowToFollowRecommendations:
        'Jeśli przestrzeganie tych zaleceń nie złagodzi uciążliwych objawów w ciągu 3-4 tygodni lub jeśli w tym okresie pojawią się niepokojące objawy, należy natychmiast skontaktować się z lekarzem rodzinnym lub gastroenterologiem w celu przeprowadzenia bardziej szczegółowej diagnostyki.',
    },
    footer: {
      email: 'info@foodid.pl',
    },
    button: {
      showAll: 'Pokaż wszystko',
      showLess: 'Pokaż mniej',
    },
  },
  years: 'lat {age}',
  testResults: {
    protocolNumber: 'Wynik testu numer:',
    testTitle: 'Nazwa testu:',
    nameSurname: 'Imię i nazwisko:',
    gender: 'Płeć:',
    birthDate: 'Data urodzenia:',
    sampleDateTime: 'Data i godzina pobrania próbki:',
    laboratoryDateTime: 'Data i godzina otrzymania próbki w laboratorium:',
    testDateTime: 'Data i godzina zakończenia badania:',
    resultsDateTime: 'Data i godzina sporządzenia wyniku:',
    sampleTypes: 'Rodzaj próbki:',
    sampleCode: 'Kod próbki:',
    method: 'Metoda:',
    testPerformedBy: 'Badanie wykonane przez:',
    testResultsConfirmed: 'Wyniki badania zatwierdzone przez:',
  },
  doctorSelection: {
    pickerPlaceholder: 'Wybierz swojego lekarza/specjalistę ...',
    doNotWantToShare: 'Nie chcę dzielić się informacjami z lekarzem',
  },
  questionnaire: {
    success: {
      title: 'Kwestionariusz wypełniony pomyślnie',
    },
    bloodCollectionNote: 'Pobranie krwi jest wliczone w cenę testu.',
  },
};
